import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  colors,
  // CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';

import VechicleInformation from 'src/components/VehicleInformation';

// import VerifiedIcon from 'src/components/icons/VerifiedIcon';
// import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
// import ConfirmationDialog from 'src/components/ConfirmationDialog';

const useStyles = makeStyles(() => ({
  trueBtn: {
    backgroundColor: colors.green[300],
    fontSize: '9px',
    color: 'white',
    '&:hover': {
      backgroundColor: colors.green[500]
    }
  },
  falseBtn: {
    backgroundColor: colors.red[300],
    color: 'white',
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.red[500]
    }
  },

  retryBtn: {
    color: 'black',
    backgroundColor: colors.yellow['400'],
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.yellow['400']
    }
  },

  errorMssg: {
    color: colors.red[500]
  },

  successMssg: {
    color: 'green'
  },

  root: {
    marginBottom: 20,
    width: '100%'
  }
}));

const VehicleDetails = ({
  className,
  // vehicle,
  customer,
  handleVerification,
  openCarUnverifyDialog,
  isPlateNumberVerified,
  setOpenCarUnVerifyDialog,
  handleUnverifyCarVerification,
  setIsPlateNumberVerified,
  vehicleGroups,
  setVehicle,
  ...rest
}) => {
  // const today = new Date();
  // const dateYear = today.getFullYear();
  // const month = String(today.getMonth() + 1).padStart(2, '0');
  // const day = String(today.getDate()).padStart(2, '0');
  // console.log(dateYear, month, day);
  // const todayDate = `${day}/${month}/${dateYear}`;
  const classes = useStyles();
  const [vehicleList, setVehicleList] = useState([]);

  // const handleVehicleVerification = async (userId, payload) => {
  //   setIsRetrying(true);
  //   try {
  //     const res = await Admin.verifyVehicle(userId, payload);

  //     if (res) {
  //       setVehicle(res.data.data);
  //       // console.log('verify res>>>', res.data.data);
  //       toast.success('Car Verification successful!');
  //       // setVerificationUpdated(res.data.data.updatedAt);
  //       setIsPlateNumberVerified(true);
  //       handleOpenVerifyDialog();
  //     }
  //   } catch (e) {
  //     console.log('errors', e);
  //     toast.error(e.message);
  //     handleOpenVerifyDialog();
  //   } finally {
  //     setIsRetrying(false);
  //   }
  // };

  // const handleUnverifyDriverVerification = async (userId, payload) => {
  //   setIsRetrying(true);
  //   try {
  //     const res = await Admin.verifyVehicle(userId, payload);
  //     if (res) {
  //       setVehicle(res.data.data);
  //       // console.log('unverify res>>>', res);
  //       toast.success('Car Verification Unverified!');
  //       // setVerificationUpdated(res.data.data.updatedAt);
  //       setIsPlateNumberVerified(false);
  //       setOpenCarUnVerifyDialog(prev => !prev);
  //     }
  //   } catch (e) {
  //     toast.error(e.message);
  //     setOpenCarUnVerifyDialog(prev => !prev);
  //   } finally {
  //     setIsRetrying(false);
  //   }
  // };

  // const handleRetryCarVerification = async userId => {
  //   setIsRetrying(true);
  //   try {
  //     const res = await Admin.retryCarVerication(userId);
  //     if (res) {
  //       // console.log('unverify res>>>', res);
  //       toast.success('Car retry verification process successful!');

  //       setIsRetrying(false);
  //       setIsRetrying(false);
  //       handleOpenRetryDialog();
  //     }
  //   } catch (e) {
  //     setIsRetrying(false);
  //     setIsRetrying(false);
  //     // toast.error(e.message);
  //     console.log('error>>', e.message);
  //     handleOpenRetryDialog();
  //   }
  // };

  useEffect(() => {
    if (vehicleGroups) {
      // setVerificationUpdated(vehicle?.updatedAt);
      setVehicleList(vehicleGroups);
    }
  }, [customer, vehicleGroups]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardContent>
          {vehicleList || vehicleList.length > 0 ? (
            <>
              {vehicleList.map((vehicle, index) => (
                <>
                  <Divider />
                  <VechicleInformation
                    vehicle={vehicle}
                    vehiclePositions={index}
                  />
                </>
              ))}
            </>
          ) : (
            <Grid>
              <Typography>No car details yet</Typography>
            </Grid>
          )}
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

VehicleDetails.propTypes = {
  className: PropTypes.string,
  vehicle: PropTypes.object,
  customer: PropTypes.object,
  handleVerification: PropTypes.func,
  openCarUnverifyDialog: PropTypes.func,
  isPlateNumberVerified: PropTypes.bool,
  setOpenCarUnVerifyDialog: PropTypes.bool,
  handleUnverifyCarVerification: PropTypes.func,
  setIsPlateNumberVerified: PropTypes.func,
  setVehicle: PropTypes.func,
  vehicleGroups: PropTypes.array
};

export default VehicleDetails;
