import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20
  },

  button: {
    width: '5.5rem'
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  closeButton: {
    display: 'flex',
    justifyContent: 'end'
  },

  dialog: {
    width: '40rem'
  }
}));

export default function RetryDialog({
  title,
  message,
  open,
  handleDialogClose,
  action,
  component,
  icons,
  isReadDialog,
  isPending
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
            {icons && <Typography>{icons}</Typography>}
            {title && <Typography>{title}</Typography>}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {message}
            </Box>
          </DialogContentText>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {component}
          </Box>
        </DialogContent>
        <DialogActions>
          {isReadDialog ? (
            <Box className={classes.closeButton}>
              <Button
                onClick={action}
                color="primary"
                variant="contained"
                autoFocus
                className={classes.button}
              >
                close
              </Button>
            </Box>
          ) : (
            <>
              <Box className={classes.buttonContainer}>
                <Button
                  onClick={handleDialogClose}
                  color="primary"
                  variant="contained"
                  autoFocus
                  className={classes.button}
                  disabled={isPending}
                >
                  cancel
                </Button>
                <Button
                  onClick={action}
                  color="primary"
                  variant="contained"
                  autoFocus
                  className={classes.button}
                  disabled={isPending}
                >
                  ok
                </Button>
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

RetryDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  handleDialogClose: PropTypes.func,
  open: PropTypes.bool,
  action: PropTypes.func,
  component: PropTypes.element,
  icons: PropTypes.element,
  isReadDialog: PropTypes.bool,
  isPending: PropTypes.bool
};
