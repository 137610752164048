import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TripRow from 'src/components/TripRow';
import TableLoader from 'src/components/TableLoader';

// import trips from './trips';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestTrips = ({ className, recentFiveTrips, isLoading, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  console.log('recent five trips>>>', recentFiveTrips);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Latest Trips" />
      <Divider />
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Available Seats</TableCell>
                    <TableCell>Distance</TableCell>
                    <TableCell>Estimated Duration</TableCell>
                    <TableCell>First Stop</TableCell>
                    <TableCell>Last Stop</TableCell>
                    <TableCell>Departure Date</TableCell>
                    <TableCell>Departure Time</TableCell>
                    <TableCell>Fare</TableCell>
                    <TableCell>Trip Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentFiveTrips.map(trip => (
                    <TripRow
                      key={trip.id}
                      hover
                      onClick={() => navigate(`/app/trips/${trip.id}`)}
                      trip={trip}
                      isRecentFiveTrip
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => navigate('/app/trips')}
            >
              View all
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
};

LatestTrips.propTypes = {
  className: PropTypes.string,
  recentFiveTrips: PropTypes.array,
  isLoading: PropTypes
};

export default LatestTrips;

// <><TableCell>{trip?.availableSeats}</TableCell>
// <TableCell>{metersToKm(trip?.distance)}</TableCell>
// <TableCell>{trip?.totalSeats}</TableCell>
// <TableCell>{secToMin(trip?.duration)}</TableCell>
// <TableCell>{tripFirstStop?.name}</TableCell>
// <TableCell>
//   {trip?.stops?.length ? trip?.stops[lastTripIndex]?.name : null}
// </TableCell>
// <TableCell>
//   {moment(trip?.departureDate).format('ddd, MMM Do YYYY')}
// </TableCell>
// <TableCell>{trip?.departureTime}</TableCell>
// <TableCell className={classes.nowrap}>
//   {formatPrice(trip?.pricing, trip?.pricingCurrency)}
// </TableCell>
// <TableCell size="smalls">
//   <TripStatus status={trip?.tripStatus} />
// </TableCell>
// <TableCell align="center">
//   {trip?.verified ? (
//     <VerifiedUserIcon style={{ color: colors.green[500] }} />
//   ) : (
//     <WarningIcon style={{ color: colors.red[500] }} />
//   )}
// </TableCell>
