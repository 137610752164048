import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  // Button,
  Card,
  CardContent,
  // Divider,
  Typography,
  makeStyles,
  Divider,
  Button
} from '@material-ui/core';
import CustomDialog from 'src/components/CustomDialog';
import { green, red } from '@material-ui/core/colors';
import { AlertCircle, CheckCircle, HelpCircle, Trash } from 'react-feather';
import storage from 'src/storage';
import TableLoader from 'src/components/TableLoader';
// import { AlertCircle, CheckCircle } from 'react-feather';
// import { green, red } from '@material-ui/core/colors';
// import CustomDialog from 'src/components/CustomDialog';
// import storage from 'src/storage';
import { useNavigate } from 'react-router';
import Admin from 'src/api/admin';
import toast from 'react-hot-toast';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({
  className,
  admin,
  sendResetPassword,
  activateAdmin,
  isLoading,
  isPending,
  ...rest
}) => {
  const isSuperAdmin = storage.getUser().role === 'super';
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [isInitiating, setIsInitiating] = useState(false);

  const handleDeleteDeactivatedAdmin = async id => {
    setIsInitiating(true);
    try {
      const res = await Admin.deleteDeactivatedAdmin(id);
      if (res) {
        navigate('/app/admins', { replace: true });
        toast.success(res.data.message);
      } else {
        toast.error('failed to delete..');
      }
    } catch (e) {
      console.log('delete admin fails..', e);
    } finally {
      setIsInitiating(false);
    }
  };

  const [dialogObj, setDialogObj] = useState({
    title: '',
    message: '',
    open: dialogOpen,
    action: () => console.log('No action specified')
  });

  let adminRole = '';

  if (admin.role) {
    if (admin.role === 'super') {
      adminRole = 'Super Admin';
    } else {
      adminRole = 'Admin';
    }
  }

  const activationButton = !admin.active ? (
    <Button
      style={{ color: green[600] }}
      size="small"
      className={classes.button}
      startIcon={<CheckCircle />}
      onClick={() => {
        setDialogObj({
          title: `Activate ${admin.firstName} ${admin.lastName}?`,
          message: 'Are you sure you want to activate this admin',
          action: () => {
            activateAdmin(true);
            setDialogOpen(false);
          }
        });

        setDialogOpen(true);
      }}
      disabled={isInitiating || isPending}
    >
      Activate
    </Button>
  ) : (
    <Button
      disabled={isInitiating || isPending}
      style={{ color: red[600] }}
      size="small"
      className={classes.button}
      startIcon={<AlertCircle />}
      onClick={() => {
        setDialogObj({
          title: `Deactivate ${admin.firstName} ${admin.lastName}?`,
          message: 'Are you sure you want to deactivate this admin',
          action: () => {
            activateAdmin(false);
            setDialogOpen(false);
          }
        });
        setDialogOpen(true);
      }}
    >
      Deactivate
    </Button>
  );

  const deleteAdminButton = !admin.active && (
    <Button
      style={{ color: red[600] }}
      size="small"
      className={classes.button}
      startIcon={<Trash />}
      onClick={() => {
        setDialogObj({
          title: `Delete ${admin.firstName} ${admin.lastName}?`,
          message: 'Are you sure you want to delete this deactivated admin?',
          action: () => {
            handleDeleteDeactivatedAdmin(admin.id);
            setDialogOpen(false);
          }
        });
        setDialogOpen(true);
      }}
      disabled={isPending || isInitiating}
    >
      Delete Admin
    </Button>
  );

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomDialog
        title={dialogObj.title}
        message={dialogObj.message}
        open={dialogOpen}
        handleDialogClose={() => setDialogOpen(false)}
        action={dialogObj.action}
      />
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src="/broken-image.jpg" />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`${admin.firstName || ''} ${admin.lastName || ''}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {adminRole}
          </Typography>
        </Box>
      </CardContent>
      {isLoading ? <TableLoader /> : <Divider />}
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
      >
        {isSuperAdmin && activationButton}
      </CardContent>
      {isLoading ? <TableLoader /> : <Divider />}
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
      >
        {isSuperAdmin && (
          <Button
            style={{ color: green[600] }}
            size="small"
            className={classes.button}
            startIcon={<HelpCircle />}
            onClick={() => {
              setDialogObj({
                title: `Reset ${admin.email} password?`,
                message: `Are you sure you want to reset this admin password?. A reset password link will be sent to ${admin.email} `,
                action: () => {
                  sendResetPassword();
                  setDialogOpen(false);
                }
              });
              setDialogOpen(true);
            }}
            disabled={isInitiating || isPending}
          >
            Reset password
          </Button>
        )}
      </CardContent>
      <Divider />
      {!admin.active && (
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          {isSuperAdmin && deleteAdminButton}
        </CardContent>
      )}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  admin: PropTypes.object,
  summary: PropTypes.object.isRequired,
  activateAdmin: PropTypes.func,
  sendResetPassword: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  setIsPending: PropTypes.bool
};

export default Profile;
