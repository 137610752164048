import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  colors,
  makeStyles,
  TableCell,
  TableRow,
  Box,
  Typography
} from '@material-ui/core';
// import { toNaira } from 'src/utils/helper';
import formatPrice from 'src/utils/formatPrice';
import TripStatus from 'src/components/TripStatus';
import moment from 'moment';
import toast from 'react-hot-toast';
import Wallet from 'src/api/wallet';
import truncateSentence from 'src/utils/truncateSentence';
import RetryDialog from './RetryDialog';
import UnverifyDialog from './UnverifyDialog';

const useStyles = makeStyles(() => ({
  apprvingBtn: {
    color: colors.yellow['400'],
    borderColor: colors.yellow['400'],
    fontSize: '9px'
  },

  approveWithDrawal: {
    color: colors.green['400'],
    borderColor: colors.green['400']
  },

  retryBtn: {
    color: colors.green[300],
    fontSize: '9px'
  },
  cancelBtn: {
    color: colors.red[300],
    fontSize: '9px'
  },
  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

const TransactionRow = ({
  transaction,
  hover,
  handleRefetch,
  transactionTime,
  isRemoveAdminAction,
  ...props
}) => {
  const {
    amount,
    amountCurrency,
    transactionStatus,
    transactionType,
    balance,
    id,
    previousBalance,
    summary,
    createdAt,
    cancelledBy,
    retryByAdmin
  } = transaction;
  const [isRetrying, setIsRetrying] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [withDrawalAction, setWithDrawalAction] = useState(transactionStatus);
  const [timeOfTransaction, setTimeOfTransaction] = useState(transactionTime);
  const [transacSummary, setTransacSummary] = useState(summary);
  const [retryDialog, setRetryDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const classes = useStyles();

  const handleRetryDialog = () => {
    setRetryDialog(prev => !prev);
  };

  const handleCancelDialog = () => {
    setCancelDialog(prev => !prev);
  };

  const handleRetryWithDrawal = async () => {
    setIsRetrying(true);
    try {
      const res = await Wallet.retryWalletWithDrawal(id, transactionStatus);
      toast.success(res.message);
      handleRefetch();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsRetrying(false);
      handleRetryDialog();
    }
  };

  const handleCancelWithDrawal = async transacId => {
    setIsCancelling(true);
    try {
      const res = await Wallet.cancelWalletWithDrawal(transacId);
      setIsCancelling(false);
      toast.success(res.message);
      handleRefetch();
      handleCancelDialog();
    } catch (error) {
      setIsCancelling(false);
      toast.error(error.message);
      handleCancelDialog();
    }
  };

  const handleButtonClick = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (transactionStatus) {
      setWithDrawalAction(transactionStatus);
      setTransacSummary(truncateSentence(summary, 30));
      setTimeOfTransaction(transactionTime);
    }
  }, [transactionStatus]);

  const withDrawalActionTitle =
    withDrawalAction === 'Failed' ? 'Retry' : 'Authorize';

  // const Balance = formatPrice((balance / 100).toFixed(2), amountCurrency);
  // console.log('bal>>>>', Balance);

  return (
    <>
      {retryDialog && (
        <RetryDialog
          open={retryDialog}
          title="Are you sure want to authorize this user withdrawal"
          message="Note! this user account will credited and funds will be processed into user account immediately!"
          handleDialogClose={handleRetryDialog}
          action={handleRetryWithDrawal}
        />
      )}
      {cancelDialog && (
        <UnverifyDialog
          open={cancelDialog}
          title="Are you sure want to cancel this user withdrawal"
          message="Note! this user account funds will be credited back into user wallet immediately!"
          handleDialogClose={handleCancelDialog}
          action={() => handleCancelWithDrawal(id)}
        />
      )}
      <TableRow
        hover={hover}
        style={{ cursor: hover ? 'pointer' : 'auto' }}
        {...props}
      >
        <TableCell>{transactionType === 'DR' ? 'DEBIT' : 'CREDIT'}</TableCell>
        {previousBalance && (
          <TableCell>
            {formatPrice((previousBalance / 100).toFixed(2), amountCurrency)}
          </TableCell>
        )}
        <TableCell>
          {formatPrice((amount / 100).toFixed(2), amountCurrency)}
        </TableCell>
        {/* checks incase of zero string value */}
        {balance !== null && balance !== undefined && (
          <TableCell className={classes.nowrap}>
            {formatPrice((balance / 100).toFixed(2), amountCurrency)}
          </TableCell>
        )}
        {transactionStatus && (
          <TableCell size="smalls">
            <TripStatus status={withDrawalAction} />
          </TableCell>
        )}
        {createdAt && (
          <TableCell>
            {moment(createdAt).format('ddd, MMMM Do YYYY HH:mm:ss A')}
          </TableCell>
        )}
        {timeOfTransaction && (
          <TableCell>
            {moment(timeOfTransaction).format('ddd, MMMM Do YYYY HH:mm:ss A')}
          </TableCell>
        )}
        {/* {transactionType === 'DR' && withDrawalAction === 'Pending' ? (
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            className={classes.approveWithDrawal}
          >
            Approve
          </Button>
        </TableCell>
      ) : (
        <TableCell>
          <Typography>No Pending Withdrawal Approval</Typography>
        </TableCell>
      )} */}
        {withDrawalAction === 'Failed' || withDrawalAction === 'Authorize' ? (
          <TableCell>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Button
                disabled={isRetrying || isCancelling}
                variant="contained"
                onClick={event => {
                  handleButtonClick(event);
                  handleRetryDialog();
                }}
                className={isRetrying ? classes.apprvingBtn : classes.retryBtn}
              >
                {isRetrying ? 'Retrying...' : withDrawalActionTitle}
              </Button>
              <Button
                disabled={isRetrying || isCancelling}
                variant="contained"
                onClick={event => {
                  handleCancelDialog();
                  handleButtonClick(event);
                }}
                className={
                  isCancelling ? classes.apprvingBtn : classes.cancelBtn
                }
              >
                {isCancelling ? 'Cancelling...' : 'Cancel'}
              </Button>
            </Box>
          </TableCell>
        ) : (
          <TableCell>No action</TableCell>
        )}

        {retryByAdmin ? (
          <TableCell>
            <Box>
              <Typography>{retryByAdmin?.name}</Typography>
            </Box>
          </TableCell>
        ) : (
          <TableCell>No Admin action</TableCell>
        )}

        {cancelledBy ? (
          <TableCell>
            <Box>
              <Typography>{cancelledBy?.name}</Typography>
            </Box>
          </TableCell>
        ) : (
          <TableCell>No Admin action</TableCell>
        )}

        {summary ? (
          <TableCell>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Typography>{transacSummary}</Typography>
            </Box>
          </TableCell>
        ) : (
          <TableCell>No Transaction Summary</TableCell>
        )}
      </TableRow>
    </>
  );
};

TransactionRow.propTypes = {
  transaction: PropTypes.object,
  hover: PropTypes.bool,
  handleRefetch: PropTypes.func.isRequired,
  transactionTime: PropTypes.string,
  isRemoveAdminAction: PropTypes.bool
};

export default TransactionRow;
