import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { toNaira } from 'src/utils/helper';
import formatPrice from 'src/utils/formatPrice';
import TripStatus from './TripStatus';

const useStyles = makeStyles(() => ({
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export default function BookingRow({ booking, hover, ...props }) {
  const classes = useStyles();
  const lastTripStopIndex = booking?.trip?.stops?.length
    ? booking?.trip?.stops?.length - 1
    : null;
  // console.log('booking data>>', booking.trip.stops[lastTripStopIndex]);
  return (
    <TableRow
      hover={hover}
      style={{ cursor: hover ? 'pointer' : 'auto' }}
      {...props}
    >
      <TableCell>{booking?.departureText}</TableCell>
      <TableCell>{booking?.destinationText}</TableCell>
      <TableCell className={classes.nowrap}>
        {formatPrice(booking?.amount, booking?.pricingCurrency, toNaira)}
      </TableCell>
      <TableCell>{booking?.paymentStatus}</TableCell>
      <TableCell size="smalls">
        <TripStatus status={booking?.bookingStatus} />
      </TableCell>
      <TableCell>
        {moment(booking?.departureDate).format('ddd, MMM Do YYYY')}
      </TableCell>
      <TableCell size="smalls">
        {booking?.trip?.stops.length ? booking?.trip?.stops[0]?.address : null}
      </TableCell>
      <TableCell>{booking?.trip?.stops[lastTripStopIndex]?.address}</TableCell>
      <TableCell>{moment(booking?.depatureTime).format('h:mm A')}</TableCell>
      <TableCell>
        {moment(booking?.createdAt).format('MMM Do YYYY, h:mm:ss A')}
      </TableCell>
    </TableRow>
  );
}

BookingRow.propTypes = {
  booking: PropTypes.object.isRequired,
  hover: PropTypes.bool
};
