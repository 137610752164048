/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
  Box,
  Button,
  colors
} from '@material-ui/core';
import toast from 'react-hot-toast';
import User from 'src/api/user';
import UnverifyDialog from 'src/components/UnverifyDialog';
import NativeSelects from 'src/components/SelectField';
import RetryDialog from 'src/components/RetryDialog';
import CustomDialog from 'src/components/CustomDialog';
import { useParams } from 'react-router';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
    width: '100%'
  },

  retryBtn: {
    color: 'black',
    backgroundColor: colors.yellow['400'],
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.yellow['400']
    }
  },

  trueBtn: {
    backgroundColor: colors.green[300],
    fontSize: '9px',
    color: 'white',
    '&:hover': {
      backgroundColor: colors.green[500]
    }
  },
  falseBtn: {
    backgroundColor: colors.red[300],
    color: 'white',
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.red[500]
    }
  },

  errorMssg: {
    color: colors.red[500]
  },

  successMssg: {
    color: 'green'
  }
}));

const DocumentDetails = ({
  className,
  customer,
  handleVerification,
  ...rest
}) => {
  const classes = useStyles();
  const { id } = useParams();
  // console.log('governmentData>>>', customer);
  const [dialogMssg, setDialogMssg] = useState({ message: '', title: '' });
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [openUnVerifyDialog, setOpenUnVerifyDialog] = useState(false);
  const [openRetryDialog, setOpenRetryDialog] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [verificationInfo, setVerificationInfo] = useState(customer);
  const [verificationMssg, setVerificationMssg] = useState(
    verificationInfo.userIdVerificationErrorMessage
  );
  const [isIdVerification, setIsIdVerification] = useState(
    verificationInfo.isIdVerified
  );
  const [isPending, setIsPending] = useState(false);

  const reasonOptions = [
    'Document verification failed due to incomplete Document Information!',
    'Document verification failed due to no NIN information record found!',
    'Document verification failed due to mismatch of uploaded image!'
  ];

  // console.log('selected value>>', selectValue);
  // console.log('verificationInfo>>', verificationInfo);
  // console.log('isIdVerification>>', isIdVerification);

  const handleSelect = event => {
    setSelectValue(event);
  };

  const handleOpenVerifyDialog = () => {
    setOpenVerifyDialog(prev => !prev);
  };

  const handleOpenUnVerifyDialog = () => {
    setOpenUnVerifyDialog(prev => !prev);
  };

  const handleOpenRetryDialog = () => {
    setOpenRetryDialog(prev => !prev);
  };

  const handleVerifyDialog = () => {
    handleOpenVerifyDialog();
    setDialogMssg({
      message: 'Note! this Customer Document information will be Verified!',
      title:
        'Are you sure you want to Unverify this Customer Document Information?'
    });
  };

  const handleUnVerifyDialog = () => {
    handleOpenUnVerifyDialog();
    setDialogMssg({
      message: 'Note! this Customer Document Information will be Unverified!',
      title:
        'Are you sure you want to Unverify this Customer Document Information?'
    });
  };

  const handleRetryDialog = () => {
    handleOpenRetryDialog();
    setDialogMssg({
      message:
        "Note this will restart this Customer's Document Verification process for this Customer!",
      title:
        "Are you sure you want to retry this Customer's Document Verification?"
    });
  };

  const handleDocumentVerification = async (userId, payload) => {
    setIsRetrying(true);
    setIsPending(true);
    try {
      const res = await User.verifyUser(userId, payload);
      if (res.status === 200 || res.data) {
        setVerificationMssg(res.data?.userIdVerificationErrorMessage);
        setIsIdVerification(res.data?.isIdVerified);
        console.log('res>>', res);
        toast.success('User Document Verification Successful!');
      }
    } catch (e) {
      console.log('errors', e);
      toast.error(e.message);
    } finally {
      setIsRetrying(false);
      setIsPending(false);
      handleOpenVerifyDialog();
    }
  };

  const handleDocumentUnVerification = async (userId, payload) => {
    setIsRetrying(true);
    setIsPending(true);
    try {
      const res = await User.verifyUser(userId, payload);
      if (res.status === 200 || res.data) {
        setVerificationMssg(res.data?.userIdVerificationErrorMessage);
        setIsIdVerification(res.data?.isIdVerified);
        // console.log('res>>', res);
        toast.success('User Document UnVerification Successful!');
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsRetrying(false);
      setIsPending(false);
      handleOpenUnVerifyDialog();
    }
  };

  const handleRetryDocumentVerification = async userId => {
    setIsRetrying(true);
    setIsPending(true);
    try {
      const res = await User.retryUserVerification(userId);
      if (res.status === 200 || res.data) {
        toast.success('Car retry verification process successful!');
      }
    } catch (e) {
      // toast.error(e.message);
      console.log(e.message);
    } finally {
      setIsRetrying(false);
      setIsPending(false);
      handleOpenRetryDialog();
    }
  };

  useEffect(() => {
    if (customer) {
      setVerificationInfo(customer);
    }
  }, [customer]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        {openVerifyDialog && (
          <CustomDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openVerifyDialog}
            isPending={isPending}
            handleDialogClose={handleOpenVerifyDialog}
            action={() => handleDocumentVerification(id, { idVerify: true })}
          />
        )}
        {openUnVerifyDialog && (
          <UnverifyDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openUnVerifyDialog}
            handleDialogClose={handleOpenUnVerifyDialog}
            isPending={isPending}
            action={() =>
              handleDocumentUnVerification(id, {
                idVerify: false,
                userIdVerificationErrorMessage: selectValue
              })
            }
            component={
              <NativeSelects
                value={selectValue}
                selectHandler={handleSelect}
                selectOptions={reasonOptions}
              />
            }
          />
        )}

        {openRetryDialog && (
          <RetryDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openRetryDialog}
            handleDialogClose={handleOpenRetryDialog}
            action={() => handleRetryDocumentVerification(id)}
            isPending={isPending}
          />
        )}
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Documents Verification</Typography>

              <>
                <Box
                  sx={{
                    display: 'flex',
                    width: '80%',
                    gap: 5,
                    alignItems: 'center',
                    marginLeft: '3rem'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 3,
                      justifyContent: 'start'
                    }}
                  >
                    <Button
                      variant="contained"
                      className={classes.trueBtn}
                      onClick={handleVerifyDialog}
                      disabled={isRetrying}
                    >
                      verify
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.falseBtn}
                      onClick={handleUnVerifyDialog}
                      disabled={isRetrying}
                    >
                      unverify
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '80%',
                    gap: 5,
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end'
                    }}
                  >
                    <Button
                      variant="contained"
                      className={classes.retryBtn}
                      onClick={handleRetryDialog}
                      disabled={isRetrying}
                    >
                      {isRetrying ? 'retrying...' : 'retry'}
                    </Button>
                  </Box>
                </Box>
              </>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography variant="h6">Reference Id</Typography>
              <Typography>
                {customer?.idCardInfo?.referenceId
                  ? customer?.idCardInfo?.referenceId
                  : 'none'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">Verification Status</Typography>
              <Typography>
                {customer?.idCardInfo?.verificationStatus
                  ? customer?.idCardInfo?.verificationStatus
                  : 'none'}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">ID Verification Status</Typography>
              <Typography
                className={
                  isIdVerification ? classes.successMssg : classes.errorMssg
                }
              >
                {isIdVerification ? 'ID Verified' : 'ID Not Verified!'}
              </Typography>
              <Typography variant="h6">Verification Message</Typography>
              <Typography
                className={
                  isIdVerification ? classes.successMssg : classes.errorMssg
                }
              >
                {isIdVerification
                  ? 'ID Verification Successful!'
                  : verificationMssg}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">Created At</Typography>
              <Typography>
                {moment(customer?.createdAt).format('ddd, MMMM Do YYYY')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">Updated At</Typography>
              <Typography>
                {moment(customer?.updatedAt).format('ddd, MMMM Do YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {customer?.idCardInfo?.verificationStatus !== 'Completed' ? (
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Typography variant="h6">
                  No Government Data Yet user should Verify from the app!
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">Verification Status</Typography>
                  <Typography>
                    {customer?.idCardInfo?.verificationStatus}
                  </Typography>
                  <Typography variant="h6">Verification Type</Typography>
                  <Typography variant="h6">
                    {customer?.idCardInfo?.verificationType
                      ? customer?.idCardInfo?.verificationType
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Registeration Date</Typography>
                  <Typography>
                    {customer?.idCardInfo?.createdAt
                      ? moment(customer?.idCardInfo?.createdAt).format(
                          'ddd, MMMM Do YYYY HH:mm:ss A'
                        )
                      : 'None'}
                  </Typography>
                  <Typography>
                    {customer?.idCardInfo?.verificationType}
                  </Typography>
                  <Typography variant="h6">Bank Name</Typography>
                  <Typography>
                    {customer?.idCardInfo?.bankName
                      ? customer?.idCardInfo?.bankName
                      : 'None'}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">Reference Id</Typography>
                  <Typography>
                    {customer?.idCardInfo?.referenceId
                      ? customer?.idCardInfo?.referenceId
                      : 'No reference ID yet!'}
                  </Typography>
                  <Typography variant="h6">Account No</Typography>
                  <Typography>
                    {customer?.idCardInfo?.accountNumber
                      ? customer?.idCardInfo?.accountNumber
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Account Name</Typography>
                  <Typography>
                    {customer?.idCardInfo?.accountName
                      ? customer?.idCardInfo?.accountName
                      : 'None'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">NIN No</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity?.nin
                      ? customer?.idCardInfo?.governmentData?.data?.entity?.nin
                      : 'none'}
                  </Typography>
                  <Typography variant="h6">Customer Id</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.customer
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          .customer
                      : 'none'}
                  </Typography>
                  <Typography variant="h6">Date Of Birth</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.date_of_birth
                      ? moment(
                          customer?.idCardInfo?.governmentData?.data?.entity
                            ?.date_of_birth
                        ).format('ddd, MMMM Do YYYY')
                      : customer?.idCardInfo?.governmentData?.entity?.birthdate
                      ? moment(
                          customer?.idCardInfo?.governmentData?.entity
                            ?.birthdate
                        ).format('ddd, MMMM Do YYYY')
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Gender</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity?.gender
                      ? customer?.idCardInfo?.governmentData?.entity?.gender
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Mobile Number</Typography>
                  <Typography>
                    {customer?.mobile
                      ? `${customer.mobileCode}${customer.mobile}`
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Registeration Date</Typography>
                  <Typography>
                    {customer?.idCardInfo?.createdAt
                      ? moment(customer?.idCardInfo?.createdAt).format(
                          'ddd, MMMM Do YYYY'
                        )
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Residential Address</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.residential_address
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.residential_address
                      : customer?.idCardInfo?.governmentData?.entity
                          ?.residence_AddressLine1
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.residence_AddressLine1
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">State Of Origin</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.state_of_origin
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.state_of_origin
                      : customer?.idCardInfo?.governmentData?.entity
                          ?.self_origin_state
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.self_origin_state
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">LGA Origin</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.entity
                      ?.self_origin_lga
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.self_origin_lga
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Place Of Origin</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.entity
                      ?.self_origin_place
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.self_origin_place
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">State Of Residence</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.state_of_residence
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.state_of_residence
                      : customer?.idCardInfo?.governmentData?.entity
                          ?.residence_state
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.residence_state
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Title</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity?.title
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.title
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Watch Listed</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.watch_listed
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.watch_listed
                      : 'None'}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">First Name</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.first_name
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.first_name
                      : customer?.idCardInfo?.governmentData?.entity?.firstname
                      ? customer?.idCardInfo?.governmentData?.entity?.firstname
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Last Name</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.last_name
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.last_name
                      : customer?.idCardInfo?.governmentData?.entity?.surname
                      ? customer?.idCardInfo?.governmentData?.entity?.surname
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Middle Name</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.middle_name
                      ? customer.idCardInfo?.governmentData?.data?.entity
                          ?.middle_name
                      : customer?.idCardInfo?.governmentData?.entity?.middlename
                      ? customer?.idCardInfo?.governmentData?.entity?.middlename
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Marital Status</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.marital_status
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.marital_status
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Account Level</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.level_of_account
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.level_of_account
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">LGA Of Residence</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.lga_of_residence
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.lga_of_residence
                      : customer?.idCardInfo?.governmentData?.entity
                          ?.residence_lga
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.residence_lga
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">State Of Residence</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.residence_state
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Residential Status</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.entity
                      ?.residencestatus
                      ? `By ${customer?.idCardInfo?.governmentData?.entity?.residencestatus}`
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Name On Card</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.name_on_card
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.name_on_card
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Nationality</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.nationality
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.nationality
                      : customer?.idCardInfo?.governmentData?.entity
                          ?.nationality
                      ? customer?.idCardInfo?.governmentData?.entity
                          ?.nationality
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Bank Enrollment</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.enrollment_bank
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.enrollment_bank
                      : 'None'}
                  </Typography>
                  <Typography variant="h6">Banck Enrollment Branch</Typography>
                  <Typography>
                    {customer?.idCardInfo?.governmentData?.data?.entity
                      ?.enrollment_branch
                      ? customer?.idCardInfo?.governmentData?.data?.entity
                          ?.enrollment_branch
                      : 'None'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}

        <Divider />
      </Card>
    </div>
  );
};

DocumentDetails.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object,
  handleVerification: PropTypes.func
};

export default DocumentDetails;
