import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
  Box,
  Button,
  colors
} from '@material-ui/core';
import VerifiedIcon from 'src/components/icons/VerifiedIcon';
import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
import UnverifyDialog from 'src/components/UnverifyDialog';
import CustomDialog from 'src/components/CustomDialog';
import { useParams } from 'react-router';
import DateInput from 'src/components/DateInput';
import NativeSelects from 'src/components/SelectField';
import toast from 'react-hot-toast';
import User from 'src/api/user';
import Admin from 'src/api/admin';
import RetryDialog from 'src/components/RetryDialog';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  trueBtn: {
    backgroundColor: colors.green[300],
    fontSize: '9px',
    color: 'white',
    '&:hover': {
      backgroundColor: colors.green[500]
    }
  },
  falseBtn: {
    backgroundColor: colors.red[300],
    color: 'white',
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.red[500]
    }
  },

  errorMssg: {
    color: colors.red[500]
  },

  successMssg: {
    color: 'green'
  },

  retryBtn: {
    color: 'black',
    backgroundColor: colors.yellow['400'],
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.yellow['400']
    }
  },

  GridCont: {
    marginLeft: '0rem'
  },

  root: {
    marginBottom: 20,
    width: '100%'
  }
}));

const today = new Date();
const dateYear = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
const todayDate = `${day}/${month}/${dateYear}`;

const DriverLicense = ({
  className,
  customer,
  handleVerification,
  isLicenseNumberVerified,
  verificationMessage,
  handleUnverifyDriverVerification,
  setIsLicenseNumberVerified,
  setVerificationMessage,
  openUnVerifyDialog,
  setOpenUnVerifyDialog,
  isPending,
  setIsPending,
  ...rest
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [dialogMssg, setDialogMssg] = useState({ message: '', title: '' });
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  // const [openUnVerifyDialog, setOpenUnVerifyDialog] = useState(false);
  const [customerData, setCustomerData] = useState(customer);
  const [date, setDate] = useState(null);
  const [selectValue, setSelectValue] = useState(todayDate);
  // const [verificationUpdated, setVerificationUpdated] = useState(customer?.updatedAt);

  const [openRetryDialog, setOpenRetryDialog] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);

  const reasonOptions = [
    'Driver Lincense verification falied due to Invalid Lincense Number!',
    'Driver Lincense verification falied due to Lincense Name not Corresponding and does not match!',
    'Driver Lincense verification falied due to License Expiry date!',
    'Driver Lincense verification falied due to Invalid Lincense Image no clear!'
  ];

  const [driverLicenseExpiryDate, setIsDriverLicenseExpiryDate] = useState(
    customer?.driverLicenseExpiryDate
  );

  const handleSelect = event => {
    setSelectValue(event);
  };

  const handleDateChange = formattedDate => {
    setDate(formattedDate);
    console.log('date>>', date);
    console.log(typeof todayDate);
  };
  const handleOpenVerifyDialog = () => {
    setOpenVerifyDialog(prev => !prev);
  };

  // const handleOpenUnVerifyDialog = () => {
  //   setOpenUnVerifyDialog(prev => !prev);
  // };

  const handleOpenRetryDialog = () => {
    setOpenRetryDialog(prev => !prev);
  };

  const handleVerifyDialog = () => {
    handleOpenVerifyDialog();
    setDialogMssg({
      message:
        "Please Verify by inputing the accurate Driver's License plate number expiry date below and press ok to verify! Note this will enable Driver's access to publish ride!",
      title: "Are you sure you want to verify this Driver's Plate Number?"
    });
  };

  const handleUnVerifyDialog = () => {
    setOpenUnVerifyDialog(prev => !prev);
    setDialogMssg({
      message: "Note this will disable Driver's access to publish ride!",
      title: "Are you sure you want to unverify this Driver's Plate Number?"
    });
  };

  const handleRetryDialog = () => {
    handleOpenRetryDialog();
    setDialogMssg({
      message:
        'Note this will restart Driver License Verification process for this Driver!',
      title:
        "Are you sure you want to retry this Driver's License Verification?"
    });
  };

  const handleVehicleVerification = async (userId, payload) => {
    setIsRetrying(true);
    setIsPending(true);
    try {
      const res = await User.verifyUser(userId, payload);
      if (res.status === 200) {
        toast.success('Driver License Verification successful!');
        setCustomerData(res.data);
        setIsLicenseNumberVerified(res.data.isDriversLicenseVerified);
        setVerificationMessage(res.data.driverLicenseVerificationErrorMessage);
        setIsDriverLicenseExpiryDate(res.data.plateNumberExpiryDate);
        // setVerificationUpdated(res.data)
        console.log('res>>>', res);
      }
    } catch (e) {
      console.log('errors', e);
      toast.error(e.message);
    } finally {
      setIsRetrying(false);
      setIsPending(false);
      handleOpenVerifyDialog();
    }
  };

  const handleRetryDriverLicenseVerification = async userId => {
    setIsRetrying(true);
    setIsPending(true);
    try {
      const res = await Admin.retryDriverLicenseVerification(userId);
      if (res === 200) {
        // console.log('retry res>>>', res);
        toast.success('Driver License Verificationprocess successful!');
      }
    } catch (e) {
      // toast.error(e.message);
      console.log(e.message);
      setIsRetrying(false);
    } finally {
      setIsRetrying(false);
      setIsPending(false);
      handleOpenRetryDialog();
    }
  };

  useEffect(() => {
    if (customer) {
      setCustomerData(customer);
    }
  }, [customer]);

  const driverLicenseInfo =
    driverLicenseExpiryDate === 'Invalid date' || undefined
      ? 'No  Plate Number Information'
      : moment(driverLicenseExpiryDate, 'DD/MM/YYYY').format(
          'ddd, MMMM Do YYYY'
        );

  // console.log('customer data from driver license date>>>', driverLicenseInfo);
  // console.log(customerData?.driverLicenseExpiryDate);
  // console.log('driver license plate>>', driverLicenseExpiryDate);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        {openVerifyDialog && (
          <CustomDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openVerifyDialog}
            handleDialogClose={handleOpenVerifyDialog}
            isPending={isPending}
            action={() =>
              handleVehicleVerification(id, {
                driversLicenseVerify: true,
                driverLicenseExpiryDate: date
              })
            }
            component={
              <DateInput
                value={date}
                onChange={handleDateChange}
                label="Select Date"
                required
              />
            }
          />
        )}
        {openUnVerifyDialog && (
          <UnverifyDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openUnVerifyDialog}
            handleDialogClose={() => setOpenUnVerifyDialog(prev => !prev)}
            isPending={isPending}
            action={() =>
              handleUnverifyDriverVerification(id, {
                driversLicenseVerify: false,
                driverLicenseVerificationErrorMessage: selectValue
              })
            }
            component={
              <NativeSelects
                value={selectValue}
                selectHandler={handleSelect}
                selectOptions={reasonOptions}
                label="Select Error Message:"
              />
            }
          />
        )}

        {openRetryDialog && (
          <RetryDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            isPending={isPending}
            open={openRetryDialog}
            handleDialogClose={handleOpenRetryDialog}
            action={() => handleRetryDriverLicenseVerification(id)}
          />
        )}
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Driver License Verification</Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '80%',
                  gap: 5,
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'start'
                  }}
                >
                  <Button
                    variant="contained"
                    className={classes.trueBtn}
                    onClick={handleVerifyDialog}
                    disabled={isRetrying}
                  >
                    verify
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.falseBtn}
                    onClick={handleUnVerifyDialog}
                    disabled={isRetrying}
                  >
                    unverify
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end'
                  }}
                >
                  <Button
                    variant="contained"
                    className={classes.retryBtn}
                    onClick={handleRetryDialog}
                    disabled={isRetrying}
                  >
                    {isRetrying ? 'retrying...' : 'retry'}
                  </Button>
                </Box>
              </Box>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography variant="h6">Reference Id</Typography>
              <Typography>
                {customerData?.idCardInfo?.referenceId
                  ? customerData?.idCardInfo?.referenceId
                  : 'none'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">
                Driver License Verification Status
              </Typography>
              <Typography
                className={
                  isLicenseNumberVerified
                    ? classes.successMssg
                    : classes.errorMssg
                }
              >
                {isLicenseNumberVerified
                  ? 'Driver License Verified!'
                  : 'Driver License not Verified!'}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">
                Driver License Verification Message
              </Typography>
              <Typography
                className={
                  isLicenseNumberVerified
                    ? classes.successMssg
                    : classes.errorMssg
                }
              >
                {isLicenseNumberVerified
                  ? "Driver's License Verification successful"
                  : verificationMessage}
              </Typography>
            </Grid>
            {typeof customer?.driverLicenseUrl === 'string' ? (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">
                    Driver License Expiry Date
                  </Typography>
                  <Typography
                    className={
                      customerData?.driverLicenseExpiryDate === null
                        ? classes.errorMssg
                        : classes.successMssg
                    }
                  >
                    {driverLicenseInfo}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">Driver License Document</Typography>
                  <Link
                    href={`${customerData?.driverLicenseUrl}`}
                    target="_blank"
                    rel="noopener"
                    style={{ color: 'green' }}
                  >
                    View Driver License
                  </Link>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">
                    Driver License Verification Status
                  </Typography>
                  {isLicenseNumberVerified ? (
                    <VerifiedIcon />
                  ) : (
                    <UnverifiedIcon />
                  )}
                </Grid>
                <Grid className={classes.GridCont} container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h6">Created At</Typography>
                    <Typography>
                      {moment(customerData?.createdAt).format(
                        'ddd, MMMM Do YYYY'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h6">Updated At</Typography>
                    <Typography>
                      {moment(customerData?.updatedAt).format(
                        'ddd, MMMM Do YYYY'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Driver License</Typography>
                <Typography>Not uploaded yet</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

DriverLicense.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object,
  handleVerification: PropTypes.func,
  isLicenseNumberVerified: PropTypes.bool,
  verificationMessage: PropTypes.string,
  handleUnverifyDriverVerification: PropTypes.func,
  setIsLicenseNumberVerified: PropTypes.func,
  setVerificationMessage: PropTypes.func,
  openUnVerifyDialog: PropTypes.bool,
  setOpenUnVerifyDialog: PropTypes.func,
  isPending: PropTypes.bool,
  setIsPending: PropTypes.bool
};

export default DriverLicense;
