/* eslint-disable no-nested-ternary */
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
  Box,
  MenuItem
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchField from 'src/components/SearchField';
import qs from 'qs';
// import { Search as SearchIcon } from 'react-feather';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'end'
  },

  searchInput: {
    flex: 1,
    marginRight: 10,
    width: '100%'
  },
  searchButton: {
    width: 50,
    height: 50
  },

  toolBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '5px'
  },

  mobileToolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '15px',
    flexDirection: 'column'
  },

  dateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  mobileDateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  mobileFormBox: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  mobileSearchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '15rem',
    justifyContent: 'start'
  },

  formBox: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  textField: {
    width: '100%'
  },

  form: {
    width: '100%'
  }
}));

export default function Toolbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchWord, setSearchWord] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const isMobileView = useMediaQuery('(max-width:768px)');
  const [activityType, setActivityType] = React.useState('');

  console.log('isMobileView', isMobileView);

  const [queries, setQueries] = useState({});

  const formattedDate = moment(filterDate).format('DD/MM/YYYY');
  // console.log('filter date', formattedDate);

  const handleSearch = e => {
    e.preventDefault();
    const { pathname } = location;
    navigate(`${pathname}?${qs.stringify({ searchWord })}`);
  };

  const handleFilterUserByDate = e => {
    e.preventDefault();
    const { pathname } = location;
    const newQueries = { ...queries, date: formattedDate };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
    console.log('filter submit date>>', formattedDate);
  };
  const handleFilterByActivity = event => {
    const selectedActivity = event.target.value;
    setActivityType(selectedActivity);

    const typeCondition =
      selectedActivity === 'vehicle' ||
      selectedActivity === 'profile' ||
      selectedActivity === 'report';
    const readCondition =
      selectedActivity === 'read' || selectedActivity === 'unread';
    // eslint-disable-next-line no-unneeded-ternary
    const readValue = selectedActivity === 'read' ? true : false;
    const { pathname } = location;

    // Create new queries based on the conditions
    const newQueries = {
      ...queries,
      ...(typeCondition && { activityType: selectedActivity }), // If typeCondition is true, add activityType and use the selectedActivity state
      ...(readCondition && { activityRead: readValue }) // If readCondition is true, add activityRead and use activityRead and pass boolean
    };

    // Navigate with updated queries
    navigate(`${pathname}?${qs.stringify(newQueries)}`);

    // Log selected activity
    console.log('selected activity>>', selectedActivity);
  };
  useEffect(() => {
    const { search } = location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query.searchWord) {
      setSearchWord(query.searchWord);
      setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
    }
    // } else if (filterDate) {
    //   setFilterDate(formattedDate);
    // }
  }, [location, formattedDate]);
  return (
    <div className={classes.root}>
      <Box mt={3}>
        <Card>
          <CardContent
            className={`${
              isMobileView ? classes.mobileToolBox : classes.toolBox
            }`}
          >
            <Box
              className={isMobileView ? classes.mobileDateBox : classes.dateBox}
            >
              {/* <Box className={
                  isMobileView ? classes.mobileFormBox : classes.formBox
                }> */}
              <form
                onSubmit={handleFilterUserByDate}
                className={
                  isMobileView ? classes.mobileFormBox : classes.formBox
                }
                style={{ width: '100%' }}
              >
                <TextField
                  className={classes.textField}
                  onChange={e => setFilterDate(e.target.value)}
                  size="small"
                  id="filter-date"
                  label="Filter by Booking Date"
                  name="filter-date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Button type="submit" variant="contained">
                  Filter
                </Button>
              </form>

              {/* </Box> */}
            </Box>
            <Box
              className={isMobileView ? classes.mobileDateBox : classes.dateBox}
            >
              <TextField
                className={classes.textField}
                variant="outlined"
                label="Activity Type"
                fullWidth
                size="small"
                select
                name="selectRole"
                value={activityType}
                onChange={handleFilterByActivity}
              >
                {' '}
                {['unread', 'read', 'vehicle', 'profile', 'report'].map(
                  status => (
                    <MenuItem
                      value={status === 'All' ? '' : status}
                      key={status}
                    >
                      {status}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Box>
            <Box
              className={`${
                isMobileView ? classes.mobileSearchBox : classes.searchBox
              }
              `}
            >
              <SearchField
                searchValue={searchWord}
                searchHandler={handleSearch}
                searchPlaceHolder="Search Customers"
                setSearch={setSearchWord}
                classes={classes}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
