import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Divider,
  Card,
  CardHeader,
  Container
} from '@material-ui/core';
import Page from 'src/components/Page';
// import qs from 'qs';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import alertSnackbarActions from 'src/store/actions/alertSnackbarActions';
import storage from 'src/storage';
import config from 'src/config';
import { useLocation } from 'react-router-dom';
import api from 'src/services/httpService';
import useQueryString from 'src/hooks/useQueryString';
import { useQuery } from 'react-query';
import Results from './Results';
import Toolbar from './Toolbar';

const fetchActivityLog = async query => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  const searchParams = new URLSearchParams(query);

  const { data } = await api.get(
    `${config.rootUrl}/admin/activities?${searchParams}`,
    axiosConfig
  );
  return data;
};

const ActivityLogView = () => {
  const { pathname } = useLocation();
  // console.log(pathname);
  // const [pagination, setPagination] = useState({});
  // const [data, setData] = useState([]);
  // const location = useLocation();
  // const [unreadSort, setUnReadSort] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQueryString] = useQueryString();

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      marginBottom: theme.spacing(3),
      paddingBottom:
        pathname === '/app/dashboard' ? theme.spacing(0) : theme.spacing(3),
      paddingTop:
        pathname === '/app/dashboard' ? theme.spacing(0) : theme.spacing(3)
    }
  }));

  const classes = useStyles();

  // ---Desmond-Fetching-Activity-lOG--From-Database---//
  // const activityLog = async queries => {
  //   // eslint-disable-next-line no-nested-ternary
  //   const query = queries
  //     ? `?pageNo=${queries.pageNo || 1}&rows=${queries.rows}`
  //     : '';
  //   api
  //     .get(`${config.rootUrl}/admin/activities${query}`, {
  //       headers: {
  //         Authorization: `Bearer ${storage.getToken()}`
  //       }
  //     })
  //     .then(({ data: isData }) => {
  //       setData(isData.data);
  //       setPagination(isData.pagination);
  //       setIsLoading(false);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  const { data = {} } = useQuery(
    ['activity-log', query],
    () => fetchActivityLog(query),
    {
      keepPreviousData: true
    }
  );

  const { data: activities = [], pagination: paginationData } = data;

  // useEffect(() => {
  //   const { search } = location;
  //   const queries = qs.parse(search, { ignoreQueryPrefix: true });
  //   if (data) {
  //     activityLog(queries);
  //     console.log('queries', queries);
  //   }
  //   // eslint-disable-next-line no-sparse-arrays
  // }, [, location]);

  // ---Desmond-Fetching-Activity-lOG--From-Database---//

  // useEffect(() => {
  //   const { search } = location;
  //   const queries = qs.parse(search, { ignoreQueryPrefix: true });
  //   axiosGetActivity(queries);
  // }, [location, axiosGetActivity]);
  // const { data, pagination } = userActivityList;

  const setLoading = () => {
    if (activities.length > 0) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLoading();
  }, [activities]);

  const activityLogs = activities.sort((a, b) => {
    return a.activityRead - b.activityRead;
  });

  const updatedActivityLog = activityLogs.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  // console.log('data>>>', data);
  // console.log('activities>>', activities);
  // console.log('activity log by date>>', updatedActivityLog);
  // console.log('pagination>>', paginationData);
  // console.log('activityLogs>>', activityLogs);
  // console.log('sorted Data>>', sortedData);
  // console.log('recent activity logs>>>', activityLogs);

  return (
    <Page className={classes.root} title="Activity Log">
      <Container maxWidth={false}>
        <Box mb={4}>
          {pathname === '/app/activity-log' && <Toolbar />}
          {pathname === '/app/dashboard' && (
            <Card>
              <CardHeader title="Activity Log" />
              <Divider />
            </Card>
          )}
          <Box mt={3}>
            <Results
              activities={updatedActivityLog}
              pagination={paginationData}
              isLoading={isLoading}
              // sortedData={sortedData}
              setQueryString={setQueryString}
            />
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

// ActivityLogView.propTypes = {
//   axiosGetActivity: PropTypes.func,
//   userActivityList: PropTypes.object
// };

const mapStateToProps = (/* state , ownProps */) => {
  return {
    // alertSnackbar: state.alertSnackbar,
    // userActivityList: state.userActivity
  };
};

const { setOpen, setMessage, setSeverity } = alertSnackbarActions;
// const { axiosGetActivity } = axiosActions;

const mapDispatchToProps = {
  setOpen,
  setMessage,
  setSeverity
  // axiosGetActivity
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogView);
