import React, { useEffect, useState } from 'react';
import { Box, colors, TableCell, TableRow, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import Admin from 'src/api/admin';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CustomDialog from 'src/components/CustomDialog';
import NativeSelects from 'src/components/SelectField';
import toast from 'react-hot-toast';

const useStyles = makeStyles(() => ({
  activeStatus: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: colors.green[300],
      color: 'white',
      cursor: 'pointer',
      textAlign: 'center'
    }
  },

  inActiveStatus: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: colors.red[300],
      color: 'white',
      cursor: 'pointer',
      textAlign: 'center'
    }
  },

  onGoingStatus: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: colors.amber[500],
      color: 'white',
      cursor: 'pointer',
      textAlign: 'center'
    }
  },

  stateId: {
    textTransform: 'uppercase'
  },

  status: {
    textAlign: 'center'
  },

  modalComponent: {
    marginTop: '0.7rem'
  },

  pendingStatus: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: colors.grey[300],
      color: 'white',
      cursor: 'default',
      textAlign: 'center'
    }
  }
}));

export default function Results({ location }) {
  const [locationData, setLocationData] = useState({});
  const [createdAt, setCreatedAt] = useState({});
  const [updatedAt, setUpdatedAt] = useState({});
  const [statusDialog, setStatusDialog] = useState(false);
  // const [selectIdValue, setSelectIdValue] = useState('');
  const [selectStatusValue, setSelectStatusValue] = useState('');
  const [isInitiating, setIsInitiating] = useState(false);

  // const [tripLocations, setTripLocations] = useState([]);
  const [date, setDate] = useState();
  const classes = useStyles();

  const handleSelectStatus = event => {
    setSelectStatusValue(event);
  };

  console.log('selectStatusValue>>', selectStatusValue);

  const handleDateChange = event => {
    const { value } = event.target;
    setDate(value);
    // setCustomDate(moment(date, 'DD/MM/YYYY').format('MMMM Do YYYY'));
    // console.log('date', formatte);
  };

  const handleStatusDialog = () => {
    setStatusDialog(prev => !prev);
  };

  // const fetchLocationsData = async () => {
  //   try {
  //     const res = await Admin.getAvaliableLocations();
  //     if (res) {
  //       setTripLocations(res.data);
  //     }
  //   } catch (e) {
  //     console.log('fail to fetch available locations>>', e);
  //   }
  // };

  const handleChangeStatus = async (statusId, payload) => {
    setIsInitiating(true);
    try {
      const res = await Admin.changeAvaliableLocationStatus(statusId, payload);

      if (res) {
        console.log('change status res>>', res);
        setLocationData(res.data);
        toast.success('Location status updated Successfully!');
        handleStatusDialog();
      }
    } catch (e) {
      toast.error(
        'Unable to Trip Location Update Status Please ensure you re inputing the suitable Trip credentials!'
      );
      console.log('fails to change trip status>>>', e);
    } finally {
      setIsInitiating(false);
    }
    console.log('change status res>>', statusId, payload);
  };

  useEffect(() => {
    if (location) {
      setLocationData(location);
      setCreatedAt(location.createdAt);
      setUpdatedAt(location.updatedAt);
      // fetchLocationsData();
    }
  }, [location]);

  // console.log('trip locations>>', tripLocations);

  // distructure dangled nanoseconds and seconds
  const {
    _nanoseconds: createdNanoseconds,
    _seconds: createdSeconds
  } = createdAt;

  const {
    _nanoseconds: updatedNanoseconds,
    _seconds: updatedSeconds
  } = updatedAt;

  // Convert createdAt to JavaScript Date object
  const createAtDateInMillis = createdSeconds * 1000 + createdNanoseconds / 1e6;
  const createdAtTime = new Date(createAtDateInMillis);
  // Convert updatedAt to JavaScript Date object
  const updatedAtDateInMillis =
    updatedSeconds * 1000 + updatedNanoseconds / 1e6;
  const updatedAtTime = new Date(updatedAtDateInMillis);

  // console.log('location data>>', locationData);
  // console.log('createdAt>>', createdAtTime);
  // console.log('createdAt>>', updatedAtTime);
  // Determine the correct status class based on the location data
  const getStatusClass = status => {
    switch (status) {
      case 'active':
        return classes.activeStatus;
      case 'inactive':
        return classes.inActiveStatus;
      case 'coming_soon':
        return classes.onGoingStatus;
      default:
        return '';
    }
  };

  const updateAvaliableLocationStatus = locationStatus => {
    switch (locationStatus) {
      case 'active':
        return new Date(date);
      case 'inactive':
        return null;
      case 'coming_soon':
        return new Date(date);
      default:
        return '';
    }
  };

  // const tripLocationsId = tripLocations.map(loc => loc.id);
  const tripLocationStatus = ['inactive', 'coming_soon', 'active'];

  return (
    <>
      {statusDialog && (
        <CustomDialog
          open={statusDialog}
          title="Are you sure you want change this Location Status"
          handleDialogClose={handleStatusDialog}
          action={() =>
            handleChangeStatus(locationData.id, {
              releaseDate: updateAvaliableLocationStatus(selectStatusValue),
              status: selectStatusValue
            })
          }
          component={
            <Box>
              {/* <Box>
                <NativeSelects
                  value={selectIdValue}
                  selectHandler={handleSelectId}
                  selectOptions={tripLocationsId}
                  label="Select Location ID:"
                />
              </Box> */}
              <Box className={classes.modalComponent}>
                <NativeSelects
                  value={selectStatusValue}
                  selectHandler={handleSelectStatus}
                  selectOptions={tripLocationStatus}
                  label="Change Location Status:"
                />
              </Box>
              {selectStatusValue !== 'inactive' && (
                <Box className={classes.modalComponent}>
                  <TextField
                    type="date"
                    label="Select Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={date}
                    onChange={handleDateChange}
                  />
                </Box>
              )}
            </Box>
          }
        />
      )}
      <TableRow>
        <TableCell>
          {moment(createdAtTime).format('ddd, MMM Do YYYY h:mm:ss A')}
        </TableCell>
        <TableCell className={classes.stateId}>{locationData?.id}</TableCell>
        <TableCell>{locationData?.name}</TableCell>
        <TableCell>
          {/* eslint-disable-next-line no-nested-ternary */}
          {locationData?.releaseDate === 'unknown'
            ? 'unknown'
            : locationData?.releaseDate === null
            ? 'unknown'
            : moment(locationData?.releaseDate).format('ddd, MMM Do YYYY')}
        </TableCell>
        <TableCell className={classes.status}>
          <Badge
            onClick={handleStatusDialog}
            className={
              isInitiating
                ? classes.pendingStatus
                : getStatusClass(locationData?.status)
            }
            badgeContent={`${locationData?.status}`}
            color="primary"
          />
        </TableCell>
        <TableCell>{location.votes}</TableCell>
        <TableCell>
          {moment(updatedAtTime).format('ddd, MMM Do YYYY h:mm:ss A')}
        </TableCell>
      </TableRow>
    </>
  );
}

Results.propTypes = {
  location: PropTypes.object
};
