import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card
} from '@material-ui/core';
// import { useQuery } from 'react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/Page';
import TablePagination from 'src/components/TablePagination';
import useQueryString from 'src/hooks/useQueryString';
import TableLoader from 'src/components/TableLoader';
import formatPrice from 'src/utils/formatPrice';
import TripStatus from 'src/components/TripStatus';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Wallet from 'src/api/wallet';
import WalletListToolbar from './Toolbar';
// import Toolbar from './Toolbar';

const useStyles = makeStyles(() => ({
  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export default function WalletList() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [query, setQueryString] = useQueryString();

  const { data = {} } = useQuery(
    ['allTransactions', query],
    () => Wallet.getAllUsersWalletList(query),
    {
      keepPreviousData: false
    }
  );

  const { data: usersWalletList = [] } = data;

  // const handleRefetch = () => {
  //   console.log('refched fired>>');
  //   // handleFetchTransaction();
  //   refetch();
  // };

  // const setLoading = () => {
  //   if (withdrawals.length > 0) {
  //     setIsLoading(false);
  //   }
  // };

  const getWallList = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getWallList();
    }, 2000);
  }, []);

  console.log('isloading>>>', isLoading);
  console.log('users wallet list>>>', usersWalletList);
  console.log('data>>>', data);

  return (
    <Page className={classes.root} title="Wallet Transactions">
      <Container maxWidth={false}>
        <Box mb={4}>
          <WalletListToolbar />
        </Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {isLoading ? (
                <TableLoader />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Created At</TableCell>
                      <TableCell>Updated At</TableCell>
                      <TableCell>Amount Currency</TableCell>
                      <TableCell>Credit</TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Balance</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Wallet Status</TableCell>
                      <TableCell>Blocked By</TableCell>
                      <TableCell>Last Withdrawal</TableCell>
                      <TableCell>Transaction Reference</TableCell>
                      <TableCell>Wallet Id</TableCell>
                      <TableCell>UserId</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersWalletList.map(details => (
                      <TableRow key={details.id}>
                        <TableCell>
                          {moment(details.createdAt).format(
                            'ddd, MMMM Do YYYY HH:mm:ss A'
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(details.updatedAt).format(
                            'ddd, MMMM Do YYYY HH:mm:ss A'
                          )}
                        </TableCell>
                        <TableCell>{details.amountCurrency}</TableCell>
                        <TableCell>
                          {formatPrice(
                            (details.credit / 100).toFixed(2),
                            details.amountCurrency
                          )}
                        </TableCell>
                        <TableCell>
                          {formatPrice(
                            (details.debit / 100).toFixed(2),
                            details.amountCurrency
                          )}
                        </TableCell>
                        <TableCell>
                          {formatPrice(
                            (details.balance / 100).toFixed(2),
                            details.amountCurrency
                          )}
                        </TableCell>
                        <TableCell>{details.description}</TableCell>
                        <TableCell>
                          <TripStatus
                            status={details.isBlocked ? 'Blocked' : 'Active'}
                          />
                        </TableCell>
                        <TableCell>
                          {details.isBlockedBy === 'false'
                            ? 'None'
                            : details.isBlockedBy}
                        </TableCell>
                        <TableCell>
                          {details.lastWithdrawalAt === null ||
                          details.lastWithdrawalAt === undefined
                            ? 'No lastwithdrawal records'
                            : moment(details.lastWithdrawalAt).format(
                                'ddd, MMMM Do YYYY HH:mm:ss A'
                              )}
                        </TableCell>
                        <TableCell>{details.transactionReference}</TableCell>
                        <TableCell>
                          {/* renders a new tab */}
                          <Link
                            to="#"
                            onClick={e => {
                              e.preventDefault();
                              window.open(
                                `/app/customers/wallet/${details.id}`,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            {details.id}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to="#"
                            onClick={e => {
                              e.preventDefault();
                              window.open(
                                `/app/customers/${details.userId}`,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            {details.userId}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
          {data?.pagination && (
            <TablePagination
              count={data?.pagination?.totalCount}
              page={data?.pagination?.currentPage}
              rowsPerPage={data?.pagination?.row}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
