import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  colors
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import Ratings from 'src/components/Ratings';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarsIcon from '@material-ui/icons/Stars';
import { useParams } from 'react-router';
import Admin from 'src/api/admin';
import CustomDialog from 'src/components/CustomDialog';
import AccordionComponent from 'src/components/Accordion';
import config from 'src/config';
import MoreInfoModal from 'src/components/MoreInfoModal';
import VerifiedIcon from 'src/components/icons/VerifiedIcon';
import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
import { Link } from 'react-router-dom';
// import Admin from 'src/api/admin';
// import { useParams } from 'react-router';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },

  py: {
    padding: '8px 0px'
  },

  buttonColor: {
    color: colors.green[300],
    fontSize: '9px'
  },

  menuItem: {
    // Add your custom styles here
    '& .MuiMenuItem-root': {
      display: 'flex',
      gap: '3px'
    }
  },

  accordionDetails: {
    padding: 0,
    width: '100%'
  },

  titleText: {
    marginTop: '0.5rem',
    marginLeft: '0.5rem'
  }
});

const RatersComponent = ({ rateList }) => {
  console.log('rateList form rate comp', rateList);
  return (
    <>
      {rateList.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {rateList?.map(rate => (
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Ratings ratingValue={rate?.rating} precision readOnly />
              <Typography>{rate?.ratedByName}</Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <p>No rate yet for this user</p>
      )}
    </>
  );
};

export default function CustomerDataCard({ cardAction = false, customer }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { id } = useParams();
  const [rateList, setRateList] = useState([]);
  const [ratersName, setRatersName] = useState([]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialog = () => {
    handleClose();
    setOpenDialog(prev => !prev);
  };

  const handleGetCustomerRatings = async () => {
    const res = await Admin.getCustomerRatings(id);
    if (res) {
      setRateList(res.data.data);
      // console.log('ratings response>>>>', res);
      // console.log('rating List>>>>', rateList);
    } else {
      console.log('couldnt get customers rate list>>>');
    }
  };

  const classes = useStyles();
  const [customersRating, setCustomersRating] = useState(
    customer?.rating?.rating
  );

  const getCustomerRaterNames = () => {
    rateList.map(rater => setRatersName(rater?.ratedByName));
  };

  useEffect(() => {
    if (customer) {
      setCustomersRating(customer?.rating?.rating);
      handleGetCustomerRatings();
      getCustomerRaterNames();
      // getRaters();
      // console.log('customer ratings>>', customersRating);
    }
  }, [customer]);

  // console.log('rating List>>>>', rateList);

  console.log('raters>>>', ratersName);
  // console.log('customer info>>>>', customer);

  const mixPanelCode = {
    devProject: 3357081,
    devView: 3863889,
    liveProject: 3357585,
    liveView: 3864385
  };

  const isStaging = config.env === 'staging';

  return (
    <AccordionComponent title="Customer Details">
      <Card className={classes.root}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '3rem'
          }}
        >
          <Typography className={classes.titleText}>
            Other Customer Infomation
          </Typography>
          <MoreInfoModal customer={customer} />
        </Box>
        <Divider />
        <CardContent>
          {typeof customer?.active === 'boolean' && (
            <Box className={classes.py}>
              <Typography
                className={classes.title}
                gutterBottom
                style={{ color: colors.grey[500] }}
              >
                Customer Status
              </Typography>
              <Typography variant="h5" component="h2">
                {customer?.active ? 'Active' : 'Not Active'}
              </Typography>
            </Box>
          )}
          <Divider />
          <Box
            className={classes.py}
            sx={{ height: '5rem', position: 'relative' }}
          >
            <Box
              sx={{ position: 'absolute', right: '-19px', cursor: 'pointer' }}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menuItem}
            >
              <MenuItem onClick={handleDialog}>View Rate List</MenuItem>
            </Menu>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Overall Ratings
            </Typography>
            <Box>
              <Ratings ratingValue={customersRating} precision readOnly />
            </Box>
          </Box>
          <Divider />
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Registeration Date
            </Typography>
            <Typography variant="h5" component="h2">
              {`${moment(customer?.createdAt).format('ddd, MMMM Do YYYY')}`}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Gender
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.gender}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Account Status
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.active ? 'Active' : 'InActive'}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Default Home Destination
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.homeAndAway?.home?.address
                ? customer?.homeAndAway?.home?.address
                : 'No Avaliable Information'}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Default Away Destination
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.homeAndAway?.away?.address
                ? customer?.homeAndAway?.away?.address
                : 'No Avaliable Information'}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Email Address
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.email ? customer?.email : 'No Avaliable Information'}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Bank Verification status
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.isBankVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Email Verification status
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.isEmailVerified ? (
                <VerifiedIcon />
              ) : (
                <UnverifiedIcon />
              )}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Phone Verification status
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.isPhoneVerified ? (
                <VerifiedIcon />
              ) : (
                <UnverifiedIcon />
              )}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Previous Email
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.previousEmail
                ? customer?.previousEmail
                : 'No Avaliable Information'}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Account Deletion Reason
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.accountDeleteRequestReason !== null
                ? customer?.accountDeleteRequestReason
                : 'No Avaliable Information'}
            </Typography>
          </Box>
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Wallet ID
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.walletId ? (
                <Link
                  onClick={e => {
                    e.preventDefault();
                    window.open(
                      `/app/customers/wallet/${customer?.walletId}`,
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                >
                  {customer?.walletId}
                </Link>
              ) : (
                'No Avaliable Information'
              )}
            </Typography>
          </Box>
          <Divider />
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              View Customer Details on Mix Panel
            </Typography>
            <Tooltip title="click to check customer details on mix panel">
              <Button
                className={classes.buttonColor}
                size="small"
                onClick={() =>
                  window.open(
                    `https://mixpanel.com/project/${
                      isStaging
                        ? mixPanelCode.devProject
                        : mixPanelCode.liveProject
                    }/view/${
                      isStaging ? mixPanelCode.devView : mixPanelCode.liveView
                    }/app/profile#distinct_id=${id}`,
                    '_blank'
                  )
                }
              >
                View More
              </Button>
            </Tooltip>
          </Box>
        </CardContent>
        <Divider />
        {cardAction && (
          <CardActions>
            <Button size="small">See More</Button>
          </CardActions>
        )}
        {openDialog && (
          <CustomDialog
            open={openDialog}
            handleDialogClose={handleDialog}
            action={handleDialog}
            title="Customer Rate List"
            icons={<StarsIcon />}
            isReadDialog
            message={<RatersComponent rateList={rateList} />}
          />
        )}
      </Card>
    </AccordionComponent>
  );
}

CustomerDataCard.propTypes = {
  customer: PropTypes.object,
  cardAction: PropTypes.bool.isRequired
};

RatersComponent.propTypes = {
  rateList: PropTypes.array
};
