import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { PropagateLoader } from 'react-spinners';
// import { v4 as uuidv4 } from 'uuid';
import {
  Container,
  makeStyles,
  Box,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Select,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useLocation } from 'react-router-dom';

// import qs from 'qs';
import User from 'src/api/user';
import alertSnackbarActions from 'src/store/actions/alertSnackbarActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useKeyBoardShutcut from './useKeyBoardShutcut';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  fab: {
    margin: theme.spacing(2)
  },
  TextField_tooltip: {
    display: 'flex'
  },
  tooltip_root: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    margin: 'auto',
    marginLeft: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  paperRoot: {
    display: 'flex',
    listStyleType: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0),
    minWidth: 1
  },

  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  input_typography: {
    backgroundColor: 'rgba(0,0,0, 0.4)',
    fontSize: 12,
    color: 'white',
    width: 'fit-content',
    padding: theme.spacing(1)
  },

  mailPatterns: {
    width: '50% !important',
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0)
  },

  inputError: {
    borderColor: 'red'
  }
}));

const NewMessage = ({ setOpen, setMessage, setSeverity }) => {
  const params = useLocation();
  // const history = useNavigate();

  const [invalidEmail, setInvalidEmail] = useState('');
  const [state, setState] = useState('singleMultipleUsers');
  const [showToolTip, setShowTooTip] = useState(false);
  const [useComma, setUseComma] = useState('');
  const [recepientChange, setRecepientChange] = useState('');
  const [recepientArry, setRecepientArray] = useState([]);
  // show enter multiple field
  const [showMultipleEmailField, setShowMultipleEmailField] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [selectedTemplate, setIsSelectedTemplate] = useState('Custom');
  const [mailSelector, setMailSelector] = useState('');
  // const [isReturningUser, setIsReturningUser] = useState(false);
  const handleSelectChange = event => {
    setState(event.target.value);

    console.log('recipent>>>', state);

    if (event.target.value === 'singleMultipleUsers') {
      setShowMultipleEmailField(true);
    } else {
      setShowMultipleEmailField(false);
    }
  };

  const handleSelectedTemplate = event => {
    const template = event.target.value === 'Template';
    setIsTemplate(template);
    setIsSelectedTemplate(event.target.value);
  };

  const handleMailSelector = event => {
    setMailSelector(event.target.value);
    console.log('template-id>>', mailSelector);
  };

  // clear the Recepient Input
  const clearRecepientInput = () => {
    setRecepientChange('');
  };

  const classes = useStyles();
  // const location = useLocation();
  // let recipient = '';
  // const { search } = location;
  // const queries = qs.parse(search, { ignoreQueryPrefix: true });
  // recipient = queries.email;

  // custom hook to push to array on touch of comma(,)
  const onKeyPress = () => {
    // validate email here before adding to array

    if (
      String(recepientChange)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setRecepientArray(prev => [...prev, recepientChange]);
      setInvalidEmail('');
    } else {
      setInvalidEmail('Invalid email');
      console.log(invalidEmail);
    }

    clearRecepientInput();
  };
  useKeyBoardShutcut([useComma], onKeyPress);
  const handleDelete = chipToDelete => () => {
    setRecepientArray(chips => chips.filter(chip => chip !== chipToDelete));
  };

  // change handler for recepient inputs
  const changeHandlerRecipient = e => {
    if (e.target.value.length >= 3) {
      setInvalidEmail('');
    }
  };

  // handle recepient change
  const handleChangeRecipient = e => {
    setRecepientChange(e.target.value);
  };
  // handle focus change
  const handleFocusChange = () => {
    setUseComma(',');
    setShowTooTip(true);
  };

  // handle Blur change
  const handleBlurChange = () => {
    setUseComma('');
    setShowTooTip(false);
  };

  useEffect(() => {
    if (params.search) {
      const email = params.search.split('=')[1];
      setRecepientArray([email]);
    }
  }, [params]);

  return (
    <Page className={classes.root} title="New Message">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              subject: '',
              message: '',
              mailTemplate: '',
              isTemplate: true // Added this to the initial values
            }}
            // validationSchema={Yup.object().shape({
            //   // recipient: Yup.string().max(255),
            //   subject: Yup.string()
            //     .max(255)
            //     .required('Subject is required'),
            //   message: Yup.string().required('Message is required'),
            //   mailTemplate: Yup.string().notRequired(
            //     'Template Message is Required'
            //   )
            // })}
            validationSchema={Yup.object().shape({
              subject: Yup.string().when('isTemplate', {
                is: false,
                then: Yup.string()
                  .required('Subject is required')
                  .max(255),
                otherwise: Yup.string().notRequired()
              }),

              message: Yup.string().when('isTemplate', {
                is: false,
                then: Yup.string().required('Message is required'),
                otherwise: Yup.string().notRequired()
              }),

              mailTemplate: Yup.string().when('isTemplate', {
                is: false,
                then: Yup.string().required('Template Message is required'),
                otherwise: Yup.string().notRequired()
              })

              // subject: Yup.string().when('isTemplate', {
              //   is: false,
              //   then: Yup.string().notRequired(),
              //   otherwise: Yup.string()
              //     .required('Subject is required')
              //     .max(255)
              // }),
              // message: Yup.string().when('isTemplate', {
              //   is: false,
              //   then: Yup.string().notRequired(),
              //   otherwise: Yup.string().required('Message is required')
              // }),
              // mailTemplate: Yup.string().when('isTemplate', {
              //   is: true,
              //   then: Yup.string().notRequired(),
              //   otherwise: Yup.string().required('Template Message is required')
              // })
              // mailTemplate: Yup.string().required(
              //   'Template Message is required'
              // )
            })}
            onSubmit={async values => {
              const submissionValues = { ...values }; // Make a copy of values

              submissionValues.recipient = recepientArry;
              if (isTemplate) {
                delete submissionValues.message; // Remove message when a template is selected
                delete submissionValues.subject; // Remove subject when a template is selected
                delete submissionValues.mailTemplate; // Remove mailTemplate when Custom is selected
              } else {
                delete submissionValues.mailTemplate; // Remove mailTemplate when Custom is selected
              }
              delete submissionValues.isTemplate;
              delete submissionValues.mailTemplate;

              setOpen(false);
              setMessage('');
              console.log('Submission Values:', submissionValues);
              setIsLoading(true);

              try {
                await User.sendEmail({
                  data: submissionValues,
                  ...(!isTemplate && {
                    param: state === 'singleMultipleUsers' ? '' : state
                  }),
                  ...(isTemplate && {
                    param:
                      selectedTemplate === 'Template'
                        ? `${mailSelector}&${state}`
                        : ''
                  })
                });
                setSeverity('success');
                setMessage('Email sent successfully');
                values.message = '';
                values.subject = '';
                setOpen(true);
                clearRecepientInput();
                setRecepientArray([]);
              } catch (err) {
                const { errors } = err.response.data;
                setSeverity('error');
                setMessage(errors[0]);
                setOpen(true);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Recipient(s)
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state}
                    onChange={handleSelectChange}
                    label="Select Recipient(s)"
                    inputProps={{
                      name: 'recipients'
                    }}
                  >
                    <MenuItem value="singleMultipleUsers">
                      Single Multiple Users
                    </MenuItem>
                    <MenuItem value="all">All Users</MenuItem>
                    <MenuItem value="hasVehicle=true">All Drivers</MenuItem>
                    <MenuItem value="verifiedDriver=true">
                      Verified Drivers
                    </MenuItem>
                    <MenuItem value="verifiedRider=true">
                      Verified Riders
                    </MenuItem>
                    <MenuItem value="active=true">Active Users</MenuItem>
                    <MenuItem value="isPhoneVerified=true">
                      Phone Verified Users
                    </MenuItem>
                    <MenuItem value="isEmailVerified=true">
                      Email Verified Users
                    </MenuItem>
                    <MenuItem value="isIdVerified=true">
                      ID Verified Users
                    </MenuItem>
                    <MenuItem value="isBankVerified=true">
                      Bank Verified Users
                    </MenuItem>
                    <MenuItem value="verifiedDriver=false">
                      Unverified Drivers
                    </MenuItem>
                    <MenuItem value="verifiedRider=false">
                      Unverified Riders
                    </MenuItem>
                    <MenuItem value="active=false">Inactive Users</MenuItem>
                    <MenuItem value="isPhoneVerified=false">
                      Phone Unverified Users
                    </MenuItem>
                    <MenuItem value="isEmailVerified=false">
                      Email Unverified Users
                    </MenuItem>
                    <MenuItem value="isIdVerified=false">
                      ID Unverified Users
                    </MenuItem>
                    <MenuItem value="isBankVerified=false">
                      Bank Unverified Users
                    </MenuItem>
                    <MenuItem value="isBankVerified=false">
                      Bank Unverified Users
                    </MenuItem>
                    <MenuItem value="returningUsers=true">
                      Returning Users
                    </MenuItem>
                  </Select>
                </FormControl>
                {showMultipleEmailField && (
                  <Box>
                    <TextField
                      error={Boolean(touched.recipient && errors.recipient)}
                      fullWidth
                      helperText={invalidEmail}
                      label="Enter email(s)"
                      margin="normal"
                      name="recipient"
                      onFocus={handleFocusChange}
                      onBlur={handleBlurChange}
                      onChange={changeHandlerRecipient}
                      onInput={handleChangeRecipient}
                      type="text"
                      value={recepientChange}
                      variant="outlined"
                    />
                    {showToolTip && (
                      <Typography className={classes.input_typography}>
                        Press comma ( , ) to enter another email
                      </Typography>
                    )}
                    <Box component="ul" className={classes.paperRoot}>
                      {recepientArry.map(data => {
                        let icon;

                        return (
                          <li key={data}>
                            <Chip
                              icon={icon}
                              label={data}
                              onDelete={handleDelete(data)}
                              className={classes.chip}
                            />
                          </li>
                        );
                      })}
                    </Box>
                  </Box>
                )}

                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.mailPatterns}`}
                  fullWidth // Ensure the FormControl spans full width if needed
                >
                  <InputLabel htmlFor="mail-pattern-select">
                    Select Mail Pattern
                  </InputLabel>
                  <Select
                    label="Select Mail Pattern" // This ensures the Select's label matches the InputLabel
                    id="mail-pattern-select"
                    value={selectedTemplate}
                    onChange={handleSelectedTemplate}
                    inputProps={{
                      name: 'selectmail'
                    }}
                  >
                    <MenuItem value="Template">Template</MenuItem>
                    <MenuItem value="Custom">Custom</MenuItem>
                  </Select>
                </FormControl>

                {isTemplate ? (
                  <>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select Template
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values.mailTemplate}
                        error={Boolean(
                          touched.mailTemplate && errors.mailTemplate
                        )} // Use Formik's error handling
                        helperText={
                          touched.mailTemplate && errors.mailTemplate
                            ? errors.mailTemplate
                            : ''
                        } // Error text
                        className={`${
                          touched.mailTemplate && errors.mailTemplate
                            ? classes.inputError
                            : ''
                        }`} // Add red border
                        onBlur={handleBlur}
                        onChange={event => {
                          handleMailSelector(event); // Custom handler to update local state
                          handleChange(event); // Update Formik's state
                        }}
                        label="Select Template"
                        inputProps={{
                          name: 'mailTemplate'
                        }}
                      >
                        <MenuItem value="type=email&templateId=returning-user-email">
                          Returning Users
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <TextField
                      error={Boolean(touched.subject && errors.subject)}
                      fullWidth
                      helperText={touched.subject && errors.subject}
                      label="Subject"
                      margin="normal"
                      name="subject"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.subject}
                      variant="outlined"
                      className={
                        touched.message && errors.message
                          ? classes.inputError
                          : ''
                      }
                    />
                    <TextField
                      error={Boolean(touched.message && errors.message)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Message"
                      margin="normal"
                      name="message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      multiline
                      rows={3}
                      value={values.message}
                      variant="outlined"
                      className={
                        touched.message && errors.message
                          ? classes.inputError
                          : ''
                      }
                    />
                  </>
                )}
                <Box my={2}>
                  {!isLoading ? (
                    <Button variant="contained" color="primary" type="submit">
                      Send message
                    </Button>
                  ) : (
                    <Box ml={10}>
                      <PropagateLoader
                        color="green"
                        loading={isLoading}
                        size={20}
                      />
                    </Box>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

NewMessage.propTypes = {
  setOpen: PropTypes.func,
  setMessage: PropTypes.func,
  setSeverity: PropTypes.func
};

const { setOpen, setMessage, setSeverity } = alertSnackbarActions;

const mapDispatchToProps = {
  setOpen,
  setMessage,
  setSeverity
};

export default connect(null, mapDispatchToProps)(NewMessage);
