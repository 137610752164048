import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography,
  colors,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { toNaira } from 'src/utils/helper';
import formatPrice from 'src/utils/formatPrice';
import CustomDialog from 'src/components/CustomDialog';
import { useParams } from 'react-router';
import Admin from 'src/api/admin';
import toast from 'react-hot-toast';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GetAppIcon from '@material-ui/icons/GetApp';
import Wallet from 'src/api/wallet';

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 20
  },
  balance: {
    fontSize: 30
  },
  button: {
    marginTop: 10,
    backgroundColor: colors.red[400],
    color: 'white',
    '&:hover': {
      backgroundColor: colors.red[800], // Custom hover color
      color: '#fff' // Custom text color on hover
    }
  },

  blockBtn: {
    marginTop: 10
  },
  cardContent: {
    position: 'relative'
  },

  iconButton: {
    position: 'absolute',
    right: 0
  }
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black
      }
    }
  }
}))(MenuItem);

const BalanceCard = ({ data, handleRefetch }) => {
  console.log('data>>', data);
  const classes = useStyles();
  const { id } = useParams();
  const [userId, setUserId] = useState(null);
  // const [isBlocking, setIsBlocking] = useState(false);
  const [isBlockedWallet, setIsBlockedWallet] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const [updateWalletBalance, setUpdateWalletBalance] = useState(false);
  const [dialogObj, setDialogObj] = useState({
    title: '',
    message: '',
    open: dialogOpen,
    action: () => console.log('No action specified')
  });

  // console.log('balance>>>', balance);

  const handleWalletOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleWalletCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUserId = () => {
    setUserId(id);
  };

  const handleDialogOpen = () => {
    setDialogOpen(prev => !prev);
  };

  const handleBlockWallet = async () => {
    // setIsBlocking(true);
    try {
      const res = await Admin.blockUserWallet(userId);
      // console.log('res>>>', res);
      if (res) {
        handleDialogOpen();
        // setIsBlocking(false);
        toast.success(res.message);
        setIsBlockedWallet(true);
      }
    } catch (e) {
      toast.error(e.message);
      console.log('unable to block user wallet>>>', e);
    }
  };

  const handleUnBlockWallet = async () => {
    // setIsBlocking(true);
    try {
      const res = await Admin.unBlockUserWallet(userId);
      // console.log('res>>>', res);
      if (res) {
        handleDialogOpen();
        // setIsBlocking(false);
        toast.success(res.message);
        setIsBlockedWallet(false);
      }
    } catch (e) {
      toast.error(e.message);
      console.log('unable to block user wallet>>>', e);
    }
  };

  const handleWalletWithDrawalOption = async () => {
    try {
      const res = await Wallet.walletWithDrawalOption(userId);
      console.log('res>>>', res);
      if (res) {
        handleDialogOpen();
        // setIsBlocking(false);
        // console.log('res>>>', res);
        toast.success(res.data.message);
        setUpdateWalletBalance(true);
        handleRefetch();
      }
    } catch (e) {
      toast.error(e.message);
      console.log('unable to block user wallet>>>', e);
    }
  };

  useEffect(() => {
    handleUserId();
    if (data) {
      setWalletBalance(data.amount);
      setIsBlockedWallet(data.isBlocked);
      setWalletBalance(data.balance);
      // console.log('wallet balance>>', walletBalance);
    }
  }, [data]);

  // console.log('wallet balance>>>', walletBalance);
  // console.log('prop balance>>', balance);
  // console.log('isBlocked>>', isBlockedWallet);
  // console.log('isBlocked>>', userId);

  // console.log('wallet balance result>>>', (walletBalance / 100).toFixed(2));
  const newWalletBalance = (walletBalance / 100).toFixed(2);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <IconButton
          className={classes.iconButton}
          aria-label="delete"
          onClick={handleWalletOpenMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleWalletCloseMenu}
        >
          {!isBlockedWallet ? (
            <StyledMenuItem
              onClick={() => {
                setDialogObj({
                  title: `Are you sure you want to block this user wallet?`,
                  message:
                    'Please note blocking user wallet will disable user wallet withdrawal!',
                  action: () => {
                    handleBlockWallet();
                  }
                });
                handleWalletCloseMenu();
                handleDialogOpen();
              }}
            >
              <ListItemIcon>
                <BlockIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Block Wallet" />
            </StyledMenuItem>
          ) : (
            <StyledMenuItem
              onClick={() => {
                setDialogObj({
                  title: `Are you sure you want to unblock this user wallet?`,
                  message:
                    'Please note unblocking user wallet will enable user wallet withdrawal!',
                  action: () => {
                    handleUnBlockWallet();
                  }
                });
                handleWalletCloseMenu();
                handleDialogOpen();
              }}
            >
              <ListItemIcon>
                <LockOpenIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Unblock Wallet" />
            </StyledMenuItem>
          )}
          <StyledMenuItem
            onClick={() => {
              setDialogObj({
                title: `Are you sure you want to withdraw funds to this User Wallet ?`,
                message:
                  'Please note withdrawing of funds will deposit all funds into this User wallet balance in the app',
                action: () => {
                  handleWalletWithDrawalOption();
                }
              });
              handleWalletCloseMenu();
              handleDialogOpen();
            }}
          >
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Withdraw Funds" />
          </StyledMenuItem>
        </StyledMenu>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            className={classes.balance}
            color="textSecondary"
            gutterBottom
          >
            {updateWalletBalance && walletBalance
              ? formatPrice(0, 'NGN', toNaira)
              : formatPrice(newWalletBalance, data.amountCurrency)}
          </Typography>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Wallet Balance
          </Typography>
          {isBlockedWallet && (
            <Button
              variant="contained"
              color="danger"
              className={classes.blockBtn}
              endIcon={<BlockIcon />}
              disabled={isBlockedWallet}
            >
              Wallet Blocked
            </Button>
          )}
        </Box>
      </CardContent>
      {dialogOpen && (
        <CustomDialog
          title={dialogObj.title}
          message={dialogObj.message}
          open={dialogOpen}
          handleDialogClose={() => setDialogOpen(false)}
          action={dialogObj.action}
        />
      )}
    </Card>
  );
};

BalanceCard.propTypes = {
  balance: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleRefetch: PropTypes.func.isRequired
};

export default BalanceCard;
