import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '24rem'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function ModernSelect({
  errorMssg,
  selectValues,
  selectHandler
}) {
  const classes = useStyles();

  console.log('age from select comp>>', errorMssg);

  console.log('selectValues>>>', selectValues);
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">
        Unverification Reason
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={errorMssg || ''}
        onChange={selectHandler}
      >
        {selectValues?.map(reason => (
          <MenuItem value={reason} key={reason}>
            {reason}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

ModernSelect.propTypes = {
  errorMssg: PropTypes.string,
  selectHandler: PropTypes.func,
  selectValues: PropTypes.array
};
