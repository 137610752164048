import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Button,
  Link,
  colors,
  IconButton,
  Box,
  MenuItem
} from '@material-ui/core';
import VerifiedIcon from 'src/components/icons/VerifiedIcon';
import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import EditableField from './EditableField';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20
  },
  cancelBtn: {
    color: colors.grey[600],
    marginRight: 10,
    '&:hover': {
      background: colors.grey[100]
    }
  }
}));

const ProfileDetails = ({
  className,
  customer,
  formData,
  onChange,
  isUpdating,
  onSave,
  handleVerification,
  ...rest
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = React.useState(false);

  const toggleEditMode = () => setIsEditMode(mode => !mode);
  const save = e => {
    e.preventDefault();
    // toggle mode when done saving
    onSave().then(() => {
      toggleEditMode();
    });
  };

  // console.log('customer>>>', customer);

  return (
    <form
      autoComplete="off"
      className={clsx(classes.root, className)}
      {...rest}
      onSubmit={save}
      disabled={isUpdating}
    >
      <Card>
        <CardHeader
          title="Profile Details"
          action={
            <div>
              {isEditMode ? (
                <div>
                  <Button
                    disabled={isUpdating}
                    color="default"
                    type="button"
                    onClick={toggleEditMode}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isUpdating}
                    color="secondary"
                    variant="outlined"
                    type="submit"
                  >
                    {isUpdating ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              ) : (
                <Button type="button" onClick={toggleEditMode}>
                  Edit
                </Button>
              )}
            </div>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={onChange}
                value={formData.firstName || ''}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                label="Last name"
                name="lastName"
                onChange={onChange}
                value={formData.lastName || ''}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <ConfirmationDialog
                title={`${
                  customer.isEmailVerified ? 'Unverify' : 'Verify'
                } Customer Email Address`}
                body="Are you sure ?"
              >
                {confirm => (
                  <EditableField
                    isEditMode={isEditMode}
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={onChange}
                    value={formData.email || ''}
                    variant="outlined"
                    required
                    renderValue={value => (
                      <Box>
                        <Link href={`mailto:${value}`} color="inherit">
                          {value}
                        </Link>
                        <Box display="inline-block" ml={1}>
                          <IconButton
                            onClick={confirm(() =>
                              handleVerification({
                                emailVerify: !customer.isEmailVerified
                              })
                            )}
                          >
                            {customer.isEmailVerified ? (
                              <VerifiedIcon fontSize="small" />
                            ) : (
                              <UnverifiedIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                  />
                )}
              </ConfirmationDialog>
            </Grid>
            <Grid item md={6} xs={12}>
              <ConfirmationDialog
                title={`${
                  customer.isPhoneVerified ? 'Unverify' : 'Verify'
                } customer phone number`}
                body="Are you sure ?"
              >
                {confirm => (
                  <EditableField
                    isEditMode={isEditMode}
                    fullWidth
                    label="Phone Number"
                    name="mobile"
                    onChange={onChange}
                    value={
                      isEditMode
                        ? `${formData?.mobile || ''}`
                        : `${formData?.mobileCode + formData?.mobile || ''}`
                    }
                    variant="outlined"
                    required
                    renderValue={value => (
                      <Box>
                        <Link href={`tel:${value}`} color="inherit">
                          {value}
                        </Link>
                        <Box display="inline-block" ml={1}>
                          <IconButton
                            onClick={confirm(() =>
                              handleVerification({
                                phoneVerify: !customer.isPhoneVerified
                              })
                            )}
                          >
                            {customer.isPhoneVerified ? (
                              <VerifiedIcon fontSize="small" />
                            ) : (
                              <UnverifiedIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                  />
                )}
              </ConfirmationDialog>
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                select
                label="Gender"
                name="gender"
                onChange={onChange}
                required
                value={formData.gender || ''}
                variant="outlined"
              >
                {['male', 'female'].map(gender => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </EditableField>
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                label="Birth year"
                name="yearOfBirth"
                onChange={onChange}
                required
                value={formData.yearOfBirth || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object,
  customer: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  isUpdating: PropTypes.bool,
  handleVerification: PropTypes.func
};

export default ProfileDetails;
