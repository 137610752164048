import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, colors } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';
import formatPrice from 'src/utils/formatPrice';
import { metersToKm, secToMin } from 'src/utils/helper';
import moment from 'moment';
import TripStatus from './TripStatus';

const useStyles = makeStyles(() => ({
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export default function TripRow({ trip, hover, isRecentFiveTrip, ...props }) {
  const classes = useStyles();
  const { firstName = '', lastName = '' } = trip.driver || {};

  const lastTripIndex = trip?.stops?.length ? trip?.stops?.length - 1 : null;
  const tripFirstStop = trip?.stops?.[0] || {};

  // console.log('last trip location>>', trip.stops[lastTripIndex]);

  // console.log('trip>>>', trip?.stops[0]);

  return (
    <TableRow
      hover={hover}
      style={{ cursor: hover ? 'pointer' : 'auto' }}
      {...props}
    >
      {!isRecentFiveTrip && (
        <TableCell className={classes.nowrap}>
          {[firstName, lastName].join(' ')}
        </TableCell>
      )}
      <TableCell>{trip?.availableSeats}</TableCell>
      <TableCell>{metersToKm(trip?.distance)}</TableCell>
      {!isRecentFiveTrip && <TableCell>{trip?.totalSeats}</TableCell>}
      <TableCell>{secToMin(trip?.duration)}</TableCell>
      <TableCell>{tripFirstStop?.name}</TableCell>
      <TableCell>
        {trip?.stops?.length ? trip?.stops[lastTripIndex]?.name : null}
      </TableCell>
      <TableCell>
        {moment(trip?.departureDate).format('ddd, MMM Do YYYY')}
      </TableCell>
      <TableCell>
        {moment(trip?.departureTime, 'HH:mm').format('hh:mm A')}
      </TableCell>
      <TableCell className={classes.nowrap}>
        {formatPrice(trip?.pricing, trip?.pricingCurrency)}
      </TableCell>
      <TableCell size="smalls">
        <TripStatus status={trip?.tripStatus} />
      </TableCell>
      {!isRecentFiveTrip && (
        <TableCell align="center">
          {trip?.verified ? (
            <VerifiedUserIcon style={{ color: colors.green[500] }} />
          ) : (
            <WarningIcon style={{ color: colors.red[500] }} />
          )}
        </TableCell>
      )}
    </TableRow>
  );
}

TripRow.propTypes = {
  trip: PropTypes.object.isRequired,
  hover: PropTypes.bool,
  isRecentFiveTrip: PropTypes.bool
};
