import { Box, InputAdornment, SvgIcon, TextField } from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import PropTypes from 'prop-types';

import React from 'react';

export default function SearchField({
  searchValue,
  searchHandler,
  setSearch,
  searchPlaceHolder,
  classes
}) {
  return (
    <Box className={classes.searchBox} maxWidth={500}>
      <form onSubmit={searchHandler} style={{ width: '100%' }}>
        <TextField
          className={classes.searchInput}
          value={searchValue}
          onChange={event => {
            setSearch(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          placeholder={searchPlaceHolder}
          variant="outlined"
        />
      </form>
    </Box>
  );
}

SearchField.propTypes = {
  searchValue: PropTypes.string,
  searchHandler: PropTypes.func,
  setSearch: PropTypes.func,
  searchPlaceHolder: PropTypes.string,
  classes: PropTypes.object
};
