/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  // Button,
  CardContent,
  Chip,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
// import VerifiedIcon from 'src/components/icons/VerifiedIcon';
// import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
import moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Admin from 'src/api/admin';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import ModernSelect from './ModernSelect';
import UnverifyDialog from './UnverifyDialog';
import CustomDialog from './CustomDialog';
import VerifiedIcon from './icons/VerifiedIcon';
import RetryDialog from './RetryDialog';
import UnverifiedIcon from './icons/UnverifiedIcon';

const useStyles = makeStyles(() => ({
  errorMssg: {
    color: colors.red[500]
  },

  successMssg: {
    color: 'green'
  },

  defaultBtn: {
    color: colors.green[300],
    fontSize: '9px'
  },

  root: {
    marginBottom: 20,
    width: '100%'
  },

  unVerifiedChip: {
    backgroundColor: colors.red[500],
    color: 'white',
    '&:hover': {
      backgroundColor: colors.red[500]
    }
  },

  verifiedChip: {
    backgroundColor: colors.green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: colors.green[500]
    }
  }
}));

const DetailsText = ({ title, type }) => {
  // const classes = useStyles();

  return (
    <>
      <Typography style={{ color: colors.grey[500] }} variant="h6">
        {title}
      </Typography>

      <Typography>{type}</Typography>
    </>
  );
};

export default function VechicleInformation({ vehicle, vehiclePositions }) {
  const classes = useStyles();
  const { id } = useParams();

  // console.log('vehicle>>>', vehicle);
  // console.log('vehicle positions>>>', vehiclePositions);
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [openUnVerifyDialog, setOpenUnVerifyDialog] = useState(false);
  const [openVerifyRoadDialog, setOpenVerifyRoadDialog] = useState(false);
  const [openUnVerifyRoadDialog, setOpenUnVerifyRoadDialog] = useState(false);
  const [openVehicleLincenseDialog, setOpenVehicleLincenseDialog] = useState(
    false
  );
  const [openDefaultVehicleDialog, setOpenDefaultVehicleDialog] = useState(
    false
  );
  const [
    openUnVerifyVehicleLincenseDialog,
    setOpenUnVerifyVehicleLincenseDialog
  ] = useState(false);
  // const [age, setAge] = React.useState('');
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');
  const [plateNumberExpiryDate, setPlateNumberExpiryDate] = useState(
    vehicle?.plateNumberExpiryDate
  );

  const [roadWorthinessExpiryDate, setRoadWorthinessExpiryDate] = useState(
    vehicle?.roadWorthinessExpiryDate
  );

  const [vehicleLicenseExpiryDate, setVehicleLicenseExpiryDate] = useState(
    vehicle?.vehicleLicenseExpiryDate
  );

  const [isCarDetailsCheck, setIsCarDetailsCheck] = useState(
    vehicle?.isCarDetailsVerified
  );
  const [isRoadDetailsCheck, setRoadDetailsCheck] = useState(
    vehicle?.isRoadWorthinessVerified
  );

  const [isVehicleLicenseCheck, setIsVehicleLicenseCheck] = useState(
    vehicle?.isVehicleLicenseVerified
  );

  const [
    carDetailsErrorMessageState,
    setCarDetailsErrorMessageState
  ] = useState(vehicle?.carDetailsErrorMessage);
  const [roadWorthinessErrorState, setRoadWorthinessErrorState] = useState(
    vehicle?.roadWorthinessErrorMessage
  );
  const [vehicleLicenseErrorState, setVehicleLicenseErrorState] = useState(
    vehicle?.vehicleLicenseErrorMessage
  );

  const [isPending, setIsPending] = useState(false);
  const [isCarVerified, setIsCarVerified] = useState(vehicle?.isVerified);
  const [isDefaultVehicle, setIsDefaultVehicle] = useState(vehicle?.isDefault);
  const vehicleDetailsErrorMessages = [
    'Car verification failed due to no Plate Number uploaded does not match with the !',
    'Car verification failed due to Plate Number Date Expired!',
    'Car verification failed due to no Car Images not fully uploaded!',
    'Car verification failed due to Car Plate Number Image not clear!'
  ];

  const roadWorthinessErrorMessages = [
    'Roadworthiness verification failed due to unclear image upload!',
    'Roadworthiness verification failed due to date expiry',
    'Roadworthiness verification failed due fake credentials',
    'Roadworthiness verification failed due to mismatch information'
  ];

  const vehicleLicenseErrorMessages = [
    'Vehicle License verification failed due to unclear image upload!',
    'Vehicle License verification failed due to date expiry',
    'Vehicle License verification failed due fake credentials',
    'Vehicle Licenseverification failed due to mismatch information'
  ];

  const [verifyPayloads, setVerifyPayload] = useState({
    title: '',
    message: '',
    date: '',
    isCarDetailsVerified: null,
    isRoadWorthinessVerified: null,
    isVehicleLicenseVerified: null
  });
  const [unVerifyPayloads, setUnverifyPayloads] = useState({
    title: '',
    message: '',
    isCarDetailsVerified: null,
    verificationErrorMessages: null,
    isRoadWorthinessVerified: null,
    isVehicleLicenseVerified: null
  });
  const handleChange = event => {
    setGeneralErrorMessage(event.target.value);
  };

  // plate number date logics
  const handlePlateNumberExpiryDate = event => {
    const { value } = event.target;
    setPlateNumberExpiryDate(value);
  };
  const plateNumberExpiryPayload = moment(
    plateNumberExpiryDate,
    'YYYY-MM-DD'
  ).format('DD/MM/YYYY');
  const formattedPlateNumberExpiry = moment(
    plateNumberExpiryPayload,
    'DD/MM/YYYY'
  ).format('MMMM Do YYYY');

  // roadworthiness date logics
  const handleRoadWorthinessExpiryDate = event => {
    const { value } = event.target;
    setRoadWorthinessExpiryDate(value);
  };

  // console.log('roadworthinessdate', roadWorthinessExpiryDate);

  const roadWorthinessExpiryPayload = moment(
    roadWorthinessExpiryDate,
    'YYYY-MM-DD'
  ).format('DD/MM/YYYY');
  const formattedRoadWorthinessExpiryDate = moment(
    roadWorthinessExpiryPayload,
    'DD/MM/YYYY'
  ).format('MMMM Do YYYY');

  // vehicle license date logics
  const handleVehicleLicenseExpiryDate = event => {
    const { value } = event.target;
    setVehicleLicenseExpiryDate(value);
  };
  const vehicleLicenseExpiryDatePayload = moment(
    vehicleLicenseExpiryDate,
    'YYYY-MM-DD'
  ).format('DD/MM/YYYY');
  const formattedVehicleLicenseExpiryDate = moment(
    vehicleLicenseExpiryDatePayload,
    'DD/MM/YYYY'
  ).format('MMMM Do YYYY');

  const handleOpenVerifyDialog = () => {
    setOpenVerifyDialog(prev => !prev);
    setVerifyPayload({
      title: `Are you sure you want to Verify Vehicle ${vehiclePositions +
        1} Information details`,
      message:
        'Ensure you select a valid plate number expiry date and check all vehicle information details before verifying. Note! this user Vehicle details will be verified!',
      date: plateNumberExpiryDate,
      isCarDetailsVerified: true
    });
  };

  const handleOpenUnVerifyDialog = () => {
    setOpenUnVerifyDialog(prev => !prev);
    setUnverifyPayloads({
      title: `Are you sure you want to Unverify Vehicle ${vehiclePositions +
        1} Information details`,
      message:
        'Note! Please Ensure you select a precise error message to state the purpose of the vehhicle details unverification!',
      isCarDetailsVerified: false,
      verificationErrorMessages: vehicleDetailsErrorMessages
    });
  };

  const handleOpenRoadWorthinessDialog = () => {
    setOpenVerifyRoadDialog(prev => !prev);
    setVerifyPayload({
      title: `Are you sure you want to Verify Vehicle ${vehiclePositions +
        1} RoadWorthiness Information details`,
      message:
        'Ensure you select a valid Roadworthiness expiry date information before verifying. Note! this user Roadworthiness details will be verified!',
      date: roadWorthinessExpiryDate,
      isRoadWorthinessVerified: true
    });
  };

  const handleOpenRoadWorthinessUnVerificationDialog = () => {
    setOpenUnVerifyRoadDialog(prev => !prev);
    setUnverifyPayloads({
      title: `Are you sure you want to Unverify Vehicle ${vehiclePositions +
        1} RoadWorthiness Information details`,
      message:
        'Note! Please Ensure you select a precise error message to state the purpose of the Roadworthiness unverification!',
      date: roadWorthinessExpiryDate,
      isRoadWorthinessVerified: false,
      verificationErrorMessages: roadWorthinessErrorMessages
    });
  };

  const handleOpenVerifyVehicleLicenseDialog = () => {
    setOpenVehicleLincenseDialog(prev => !prev);
    setVerifyPayload({
      title: `Are you sure you want to Verify Vehicle ${vehiclePositions +
        1} License Information details`,
      message:
        'Ensure you select a valid vehicle license expiry date information before verifying. Note! this user vehicle details will be verified!',
      date: vehicleLicenseExpiryDate,
      isVehicleLicenseVerified: true
    });
  };

  const handleOpenUnVerifyVehicleLicenseDialog = () => {
    setOpenUnVerifyVehicleLincenseDialog(prev => !prev);
    setUnverifyPayloads({
      title: `Are you sure you want to Unverify Vehicle ${vehiclePositions +
        1} License Information details`,
      message:
        'Note! Please Ensure you select a precise error message to state the purpose of the Vehicle license unverification!',
      date: vehicleLicenseExpiryDate,
      isVehicleLicenseVerified: false,
      verificationErrorMessages: vehicleLicenseErrorMessages
    });
  };

  const handleOpenDefaultVehicleDialog = () => {
    setOpenDefaultVehicleDialog(prev => !prev);
  };

  const handleVehicleVerification = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      console.log('res>>>', res);

      if (res.status === 200 || res.data) {
        console.log('res>>>', res);
        setIsCarDetailsCheck(res.data.data.isCarDetailsVerified);
        setIsCarVerified(res.data.data.isVerified);
        toast.success('Car Verification Details Verified Successfully!');
      }
    } catch (e) {
      console.log('errors', e.message);
      toast.error(e.message);
    } finally {
      setIsPending(false);
      setOpenVerifyDialog(prev => !prev);
    }
  };

  const handleUnverifyDriverVerification = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      if (res.status === 200 || res.data) {
        console.log('unverify res>>>', res);
        setIsCarDetailsCheck(res.data.data.isCarDetailsVerified);
        setCarDetailsErrorMessageState(res.data.data.carDetailsErrorMessage);
        setIsCarVerified(res.data.data.isVerified);
        toast.success('Car Verification Details UnVerified Successfully!');
      }
    } catch (e) {
      toast.error(e.message);
      console.log('error>>', e.message);
    } finally {
      setIsPending(false);
      setOpenUnVerifyDialog(prev => !prev);
    }
  };

  const handleRoadWorthinessVerification = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      if (res.status === 200 || res.data) {
        console.log('verify res>>>', res);
        setRoadDetailsCheck(res.data.data.isRoadWorthinessVerified);
        setIsCarVerified(res.data.data.isVerified);
        toast.success('RoadWorthiness Verified Successfully!');
      }
    } catch (e) {
      toast.error(e.message);
      console.log('error>>', e.message);
    } finally {
      setIsPending(false);
      setOpenVerifyRoadDialog(prev => !prev);
    }
  };

  const handleRoadWorthinessUnVerification = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      if (res.status === 200 || res.data) {
        console.log('unverify res>>>', res);
        setRoadDetailsCheck(res.data.data.isRoadWorthinessVerified);
        setRoadWorthinessErrorState(res.data.data.roadWorthinessErrorMessage);
        setIsCarVerified(res.data.data.isVerified);
        toast.success('RoadWorthiness UnVerified Successfully!');
      }
    } catch (e) {
      toast.error(e.message);
      console.log('error>>', e.message);
    } finally {
      setIsPending(false);
      setOpenUnVerifyRoadDialog(prev => !prev);
    }
  };
  const handleVehicleLicenseVerification = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      console.log('verify res>>>', res);
      if (res.status === 200 || res.data) {
        console.log('verify res>>>', res);
        setIsVehicleLicenseCheck(res.data.data.isVehicleLicenseVerified);
        setIsCarVerified(res.data.data.isVerified);
        toast.success('Vehicle License Verified Successfully!');
      }
    } catch (e) {
      toast.error(e.message);
      console.log('error>>', e.message);
    } finally {
      setIsPending(false);
      setOpenVehicleLincenseDialog(prev => !prev);
    }
  };

  const handleVehicleLicenseUnVerification = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      console.log('unverify res>>>', res);

      if (res.status === 200 || res.data) {
        console.log('unverify res>>>', res);
        setIsVehicleLicenseCheck(res.data.data.isVehicleLicenseVerified);
        setVehicleLicenseErrorState(res.data.data.vehicleLicenseErrorMessage);
        setIsCarVerified(res.data.data.isVerified);
        toast.success('Vehicle License UnVerified Successfully!');
      }
    } catch (e) {
      toast.error(e.message);
      console.log('error>>', e.message);
    } finally {
      setIsPending(false);
      setOpenUnVerifyVehicleLincenseDialog(prev => !prev);
    }
  };

  const handleSetDefaultVehicle = async (userId, payload) => {
    setIsPending(true);
    try {
      const res = await Admin.setDefaultVehicle(userId, payload);
      if (res.status === 200 || res.data) {
        console.log('res>>>', res);
        toast.success('Default Vehicle set successfully!');
        setIsDefaultVehicle(res.data.isDefault);
      }
    } catch (e) {
      toast.error(e.message);
      console.log('error>>', e.message);
    } finally {
      setIsPending(false);
      handleOpenDefaultVehicleDialog();
    }
  };

  useEffect(() => {
    if (vehicle) {
      setIsCarDetailsCheck(vehicle.isCarDetailsVerified);
      setPlateNumberExpiryDate(vehicle?.plateNumberExpiryDate);
      setCarDetailsErrorMessageState(vehicle?.carDetailsErrorMessage);
      setRoadDetailsCheck(vehicle?.isRoadWorthinessVerified);
      setIsVehicleLicenseCheck(vehicle?.isVehicleLicenseVerified);
      setRoadWorthinessExpiryDate(vehicle?.roadWorthinessExpiryDate);
      setRoadWorthinessErrorState(vehicle?.roadWorthinessErrorMessages);
      setVehicleLicenseExpiryDate(vehicle?.vehicleLicenseExpiryDate);
      setIsDefaultVehicle(vehicle?.isDefault);
      setIsCarVerified(vehicle?.isVerified);
    }
  }, []);

  // console.log('verification state>>', verifyPayloads);
  // console.log('unverification state>>', unVerifyPayloads);
  // console.log('car details error message state>>', carDetailsErrorMessageState);

  // expiry date info
  const expiryDateInfo =
    formattedPlateNumberExpiry === 'Invalid date'
      ? 'No Plate nuumber expiry date'
      : formattedPlateNumberExpiry;

  const roadWorthinessDateInfo =
    formattedRoadWorthinessExpiryDate === 'Invalid date'
      ? 'No road worthiness expiry date'
      : formattedRoadWorthinessExpiryDate;

  const vehicleLicenseDateInfo =
    formattedVehicleLicenseExpiryDate === 'Invalid date'
      ? 'No vehicle license expiry date'
      : formattedVehicleLicenseExpiryDate;

  // error info
  const carDetailsErrorMessageInfo =
    carDetailsErrorMessageState === null ||
    carDetailsErrorMessageState === undefined
      ? 'All cleared no car details error message'
      : carDetailsErrorMessageState;

  const roadWorthinessErrorInfo =
    roadWorthinessErrorState === null || roadWorthinessErrorState === undefined
      ? 'All cleared no road worthiness error message'
      : roadWorthinessErrorState;

  const vehicleLicenseErrorInfo =
    vehicleLicenseErrorState === null || vehicleLicenseErrorState === undefined
      ? 'All cleared no vehicle license error message'
      : vehicleLicenseErrorState;

  console.log('is vehicle license check>>', isVehicleLicenseCheck);

  return (
    <Box key={vehicle.id}>
      {openVerifyDialog && (
        <CustomDialog
          title={verifyPayloads.title}
          message={verifyPayloads.message}
          open={openVerifyDialog}
          handleDialogClose={handleOpenVerifyDialog}
          isPending={isPending}
          component={
            <TextField
              type="date"
              label="Plate Number Expiry Date"
              InputLabelProps={{
                shrink: true
              }}
              value={verifyPayloads.date}
              onChange={handlePlateNumberExpiryDate}
            />
          }
          action={() =>
            handleVehicleVerification(id, {
              vehicleId: vehicle?.id,
              expiryDate: plateNumberExpiryDate,
              isCarDetailsVerified: verifyPayloads.isCarDetailsVerified
            })
          }
        />
      )}

      {openUnVerifyDialog && (
        <UnverifyDialog
          title={unVerifyPayloads.title}
          message={unVerifyPayloads.message}
          open={openUnVerifyDialog}
          handleDialogClose={handleOpenUnVerifyDialog}
          isPending={isPending}
          action={() =>
            handleUnverifyDriverVerification(id, {
              vehicleId: vehicle.id,
              isCarDetailsVerified: unVerifyPayloads.isCarDetailsVerified,
              carDetailsErrorMessage: generalErrorMessage
            })
          }
          component={
            <ModernSelect
              selectValues={unVerifyPayloads.verificationErrorMessages}
              errorMssg={generalErrorMessage}
              selectHandler={handleChange}
            />
          }
        />
      )}

      {openVerifyRoadDialog && (
        <CustomDialog
          title={verifyPayloads.title}
          message={verifyPayloads.message}
          open={openVerifyRoadDialog}
          handleDialogClose={handleOpenRoadWorthinessDialog}
          isPending={isPending}
          component={
            <TextField
              type="date"
              label="Roadworthiness Expiry Date"
              InputLabelProps={{
                shrink: true
              }}
              value={verifyPayloads.date}
              onChange={handleRoadWorthinessExpiryDate}
            />
          }
          action={() =>
            handleRoadWorthinessVerification(id, {
              vehicleId: vehicle?.id,
              expiryDate: roadWorthinessExpiryDate,
              isRoadWorthinessVerified: verifyPayloads.isRoadWorthinessVerified
            })
          }
        />
      )}

      {openUnVerifyRoadDialog && (
        <UnverifyDialog
          title={unVerifyPayloads.title}
          message={unVerifyPayloads.message}
          open={openUnVerifyRoadDialog}
          handleDialogClose={handleOpenRoadWorthinessUnVerificationDialog}
          isPending={isPending}
          action={() =>
            handleRoadWorthinessUnVerification(id, {
              vehicleId: vehicle.id,
              isRoadWorthinessVerified:
                unVerifyPayloads.isRoadWorthinessVerified,
              roadWorthinessErrorMessage: generalErrorMessage
            })
          }
          component={
            <ModernSelect
              selectValues={unVerifyPayloads.verificationErrorMessages}
              errorMssg={generalErrorMessage}
              selectHandler={handleChange}
            />
          }
        />
      )}

      {openVehicleLincenseDialog && (
        <CustomDialog
          title={verifyPayloads.title}
          message={verifyPayloads.message}
          open={openVehicleLincenseDialog}
          handleDialogClose={handleOpenVerifyVehicleLicenseDialog}
          isPending={isPending}
          component={
            <TextField
              type="date"
              label="Vehicle License Expiry Date"
              InputLabelProps={{
                shrink: true
              }}
              value={verifyPayloads.date}
              onChange={handleVehicleLicenseExpiryDate}
            />
          }
          action={() =>
            handleVehicleLicenseVerification(id, {
              vehicleId: vehicle?.id,
              expiryDate: vehicleLicenseExpiryDate,
              isVehicleLicenseVerified: verifyPayloads.isVehicleLicenseVerified
            })
          }
        />
      )}
      {openUnVerifyVehicleLincenseDialog && (
        <UnverifyDialog
          title={unVerifyPayloads.title}
          message={unVerifyPayloads.message}
          open={openUnVerifyVehicleLincenseDialog}
          handleDialogClose={handleOpenUnVerifyVehicleLicenseDialog}
          isPending={isPending}
          action={() =>
            handleVehicleLicenseUnVerification(id, {
              vehicleId: vehicle.id,
              isVehicleLicenseVerified:
                unVerifyPayloads.isVehicleLicenseVerified,
              vehicleLicenseErrorMessage: generalErrorMessage
            })
          }
          component={
            <ModernSelect
              selectValues={unVerifyPayloads.verificationErrorMessages}
              errorMssg={generalErrorMessage}
              selectHandler={handleChange}
            />
          }
        />
      )}
      {openDefaultVehicleDialog && (
        <RetryDialog
          isPending={isPending}
          open={openDefaultVehicleDialog}
          title="Are you want to make this user vehicle set as the default ride vehicle?"
          message="Note! this vehicle will automatically set as the default vehicle for this user!"
          handleDialogClose={handleOpenDefaultVehicleDialog}
          action={() =>
            handleSetDefaultVehicle(id, {
              vehicleId: vehicle.id
            })
          }
        />
      )}
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              mr: '1rem',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              gap: 3
            }}
          >
            <Typography>
              Vehicle Information No {vehiclePositions + 1}
            </Typography>
            {isCarVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
          </Box>
          {isDefaultVehicle ? (
            <Box
              sx={{
                mr: '1rem',
                alignItems: 'center',
                fontSize: '12px',
                fontWeight: 'bold'
              }}
            >
              <Button
                variant="contained"
                className={classes.defaultBtn}
                disabled={isDefaultVehicle}
              >
                Default Vehicle
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                mr: '1rem',
                alignItems: 'center',
                fontSize: '12px',
                fontWeight: 'bold'
              }}
            >
              <Button
                variant="contained"
                className={classes.defaultBtn}
                onClick={handleOpenDefaultVehicleDialog}
              >
                Make Default Vehicle
              </Button>
            </Box>
          )}
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid key={vehicle.id} container spacing={3}>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Car Make"
              type={vehicle.make ? vehicle.make : 'No vehicle make'}
            />
          </Grid>
          <Grid item md={6} xs={12} justifyContent="end">
            <DetailsText
              title="Car Model"
              type={vehicle.model ? vehicle.model : 'No vehicle model'}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Car Color"
              type={vehicle.color ? vehicle.color : 'No vehicle color'}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Car Plate Number"
              type={
                vehicle.licensePlate ? vehicle.licensePlate : 'No Plate Number'
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Car Type"
              type={vehicle.type ? vehicle.type : 'no vehicle Type'}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Car Year"
              type={vehicle.year ? vehicle.year : 'No vehicle year'}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Plate Number Expiry Date"
              type={expiryDateInfo}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Car Details Error Message"
              type={carDetailsErrorMessageInfo}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="RoadWorthiness Expiry Date"
              type={roadWorthinessDateInfo}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="RoadWorthiness Error Message"
              type={roadWorthinessErrorInfo}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Vehicle License Expiry Date"
              type={vehicleLicenseDateInfo}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DetailsText
              title="Vehicle License Error Message"
              type={vehicleLicenseErrorInfo}
            />
          </Grid>
          <Divider />
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                gap: '0.5rem'
              }}
            >
              <Box>
                <Typography variant="h6">Verify Details</Typography>
              </Box>
              <Box sx={{ mt: '-6px' }}>
                {isCarDetailsCheck ? (
                  <Chip
                    label="Verified"
                    clickable
                    color="secondary"
                    onClick={handleOpenUnVerifyDialog}
                    icon={<DoneIcon />}
                    classes={{ root: classes.verifiedChip }}
                    disabled={isPending}
                  />
                ) : (
                  <Chip
                    label="Unverified"
                    clickable
                    color=""
                    onClick={handleOpenVerifyDialog}
                    classes={{ root: classes.unVerifiedChip }}
                    icon={<ClearIcon />}
                    disabled={isPending}
                  />
                )}
              </Box>
            </Box>

            {vehicle.imageUrls.length > 0 ? (
              <Grid container>
                {vehicle.imageUrls
                  .filter(v => v !== null && v !== undefined) // Filter out null and undefined values
                  .map((v, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item md={12} xs={12} key={index}>
                      <Box sx={{ my: '1rem' }}>
                        <Tooltip
                          title={`Click to expand ${
                            index === 0
                              ? 'Front view'
                              : // eslint-disable-next-line no-nested-ternary
                              index === 1
                              ? 'Side view'
                              : index === 2
                              ? 'Back view'
                              : 'Interior view'
                          }`}
                        >
                          <Chip
                            avatar={<Avatar alt="VI" src={v} />}
                            // eslint-disable-next-line no-nested-ternary
                            label={
                              index === 0
                                ? 'Front view'
                                : // eslint-disable-next-line no-nested-ternary
                                index === 1
                                ? 'Side view'
                                : index === 2
                                ? 'Back view'
                                : 'Interior view'
                            }
                            onClick={() => window.open(v, '_blank')}
                            variant="outlined"
                            color="primary"
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Typography>No Car Image uploaded</Typography>
            )}
          </Grid>
          <Divider />
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                gap: '0.5rem'
              }}
            >
              <Box>
                <Typography variant="h6">Verify Roadworthiness</Typography>
              </Box>
              <Box sx={{ mt: '-6px' }}>
                {isRoadDetailsCheck ? (
                  <Chip
                    label="Verified"
                    clickable
                    color="secondary"
                    onClick={handleOpenRoadWorthinessUnVerificationDialog}
                    icon={<DoneIcon />}
                    classes={{ root: classes.verifiedChip }}
                    disabled={isPending}
                  />
                ) : (
                  <Chip
                    label="Unverified"
                    clickable
                    color=""
                    onClick={handleOpenRoadWorthinessDialog}
                    classes={{ root: classes.unVerifiedChip }}
                    icon={<ClearIcon />}
                    disabled={isPending}
                  />
                )}
              </Box>
            </Box>
            <Box sx={{ mt: '1rem' }}>
              {vehicle.roadWorthinessImageUrl ? (
                <Chip
                  avatar={
                    <Avatar alt="RW" src={vehicle.roadWorthinessImageUrl} />
                  }
                  // eslint-disable-next-line no-nested-ternary
                  label="Roadworthiness credentials"
                  onClick={() =>
                    window.open(vehicle.roadWorthinessImageUrl, '_blank')
                  }
                  variant="outlined"
                  color="primary"
                />
              ) : (
                <Typography>No Roadwordiness image uploaded</Typography>
              )}
            </Box>
          </Grid>
          <Divider />
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                gap: '0.5rem'
              }}
            >
              <Box>
                <Typography variant="h6">Verify Vehicle License</Typography>
              </Box>
              <Box sx={{ mt: '-6px' }}>
                {isVehicleLicenseCheck ? (
                  <Chip
                    label="Verified"
                    clickable
                    color="secondary"
                    onClick={handleOpenUnVerifyVehicleLicenseDialog}
                    icon={<DoneIcon />}
                    classes={{ root: classes.verifiedChip }}
                    disabled={isPending}
                  />
                ) : (
                  <Chip
                    label="Unverified"
                    clickable
                    color=""
                    onClick={handleOpenVerifyVehicleLicenseDialog}
                    classes={{ root: classes.unVerifiedChip }}
                    icon={<ClearIcon />}
                    disabled={isPending}
                  />
                )}
              </Box>
            </Box>
            <Box sx={{ mt: '1rem' }}>
              {vehicle.vehicleLicenseImageUrl ? (
                <Chip
                  avatar={
                    <Avatar alt="RW" src={vehicle.vehicleLicenseImageUrl} />
                  }
                  // eslint-disable-next-line no-nested-ternary
                  label="Verified"
                  onClick={() =>
                    window.open(vehicle.vehicleLicenseImageUrl, '_blank')
                  }
                  variant="outlined"
                  color="primary"
                />
              ) : (
                <Typography>No Vehicle License image uploaded</Typography>
              )}
            </Box>
          </Grid>
          <Divider />
          <Grid container spacing={1}>
            <Box
              sx={{ ml: '1rem', display: 'flex', width: '100%', mt: '1rem' }}
            >
              <Grid item md={6} xs={12}>
                <Typography variant="h6">Created At</Typography>
                <Typography>
                  {vehicle.createdAt
                    ? moment(vehicle.createdAt).format('ddd, MMMM Do YYYY')
                    : 'No Created Date'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="h6">Updated At</Typography>
                <Typography>
                  {vehicle.updatedAt
                    ? moment(vehicle.updatedAt).format('ddd, MMMM Do YYYY')
                    : 'No Updated Date'}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

VechicleInformation.propTypes = {
  vehicle: PropTypes.object,
  vehiclePositions: PropTypes.number
};

DetailsText.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string
};
