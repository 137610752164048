import {
  Card,
  CardContent,
  makeStyles,
  MenuItem,
  TextField,
  useMediaQuery,
  Box
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import SearchField from 'src/components/SearchField';
import qs from 'qs';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'end'
  },

  searchInput: {
    flex: 1,
    marginRight: 10,
    width: '100%'
  },
  searchButton: {
    width: 50,
    height: 50
  },

  toolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '5px'
  },

  mobileToolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '15px',
    flexDirection: 'column'
  },

  dateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  mobileDateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  mobileFormBox: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  mobileSearchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '15rem',
    justifyContent: 'start'
  },

  formBox: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  textField: {
    width: '100%'
  }
}));

export default function LocationToolbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchWord, setSearchWord] = useState('');
  const [role, setRole] = useState('');
  const isMobileView = useMediaQuery('(max-width:768px)');
  const [queries, setQueries] = useState({});
  // console.log('filter date', formattedDate);

  const handleSearch = e => {
    e.preventDefault();
    const { pathname } = location;
    navigate(`${pathname}?${qs.stringify({ searchWord })}`);
  };

  const handleFilterByRole = event => {
    const selectedRole = event.target.value;
    setRole(selectedRole);
    const { pathname } = location;
    const newQueries = { ...queries, status: selectedRole };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
    console.log('selected role>>', selectedRole);
  };

  useEffect(() => {
    const { search } = location;
    const queryy = qs.parse(search, { ignoreQueryPrefix: true });
    if (queryy.searchWord || queryy.reportedBy) {
      setSearchWord(queryy.searchWord);
      setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
    }
  }, [location, role]);
  return (
    <div className={clsx(classes.root)}>
      <Box mt={3}>
        <Card>
          <CardContent
            className={`${
              isMobileView ? classes.mobileToolBox : classes.toolBox
            }`}
          >
            <Box
              className={isMobileView ? classes.mobileDateBox : classes.dateBox}
            >
              <TextField
                className={classes.textField}
                variant="outlined"
                label="Filter By Status"
                fullWidth
                size="small"
                select
                name="selectRole"
                value={role}
                onChange={handleFilterByRole}
              >
                {' '}
                {['All', 'inactive', 'active', 'coming_soon'].map(status => (
                  <MenuItem value={status === 'All' ? '' : status} key={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              className={`${
                isMobileView ? classes.mobileSearchBox : classes.searchBox
              }
            `}
            >
              <SearchField
                searchValue={searchWord}
                searchHandler={handleSearch}
                searchPlaceHolder="Search Reports"
                setSearch={setSearchWord}
                classes={classes}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
