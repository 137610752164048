import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Box, Card, Menu, MenuItem, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import CustomDialog from './CustomDialog';

const useStyles = makeStyles({
  py: {
    padding: '8px 0px'
  },

  title: {
    fontSize: 14
  },

  menuItem: {
    // Add your custom styles here
    '& .MuiMenuItem-root': {
      display: 'flex',
      gap: '3px'
    }
  }
});

export default function MoreInfoModal({ customer }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialog = () => {
    handleClose();
    setOpenDialog(prev => !prev);
  };

  const MoreInfoComponent = () => {
    return (
      <Box padding={2}>
        <Card
          elevation={3}
          style={{ padding: '16px', backgroundColor: '#f9f9f9' }}
        >
          <h3>Customer Data From DataBase</h3>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              margin: 0,
              fontSize: '10px'
            }}
          >
            {JSON.stringify(customer, null, 2)}
          </pre>
        </Card>
      </Box>
    );
  };

  //   console.log('customer prop>>', customer);
  // console.log('customer details>>', customerDetails);

  return (
    <>
      <Box className={classes.py} sx={{ height: '5rem', position: 'relative' }}>
        <Box
          sx={{ position: 'absolute', right: 0, cursor: 'pointer' }}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menuItem}
        >
          <MenuItem onClick={handleDialog}>See More Info</MenuItem>
        </Menu>
      </Box>
      {openDialog && (
        <CustomDialog
          open={openDialog}
          handleDialogClose={handleDialog}
          action={handleDialog}
          title="More Customer Infomation"
          icons={<PersonIcon />}
          isReadDialog
          message={<MoreInfoComponent />}
        />
      )}
    </>
  );
}

MoreInfoModal.propTypes = {
  customer: PropTypes.object
};
