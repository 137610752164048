// Parent component
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card
} from '@material-ui/core';
import { useQuery } from 'react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/Page';
import TablePagination from 'src/components/TablePagination';
import useQueryString from 'src/hooks/useQueryString';
// import wallet from 'src/api/wallet';
import TransactionRow from 'src/components/TransactionRow';
import TableLoader from 'src/components/TableLoader';
import { useNavigate } from 'react-router-dom';
import Wallet from 'src/api/wallet';
import Toolbar from './Toolbar';

const useStyles = makeStyles(() => ({
  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export default function WalletTransactions() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQueryString] = useQueryString();

  const navigate = useNavigate();

  const { data = {}, refetch } = useQuery(
    ['allTransactions', query],
    () => Wallet.getAllWalletTransactions(query),
    {
      keepPreviousData: false
    }
  );

  const { data: withdrawals = [] } = data;

  const handleRefetch = () => {
    console.log('refched fired>>');
    // handleFetchTransaction();
    refetch();
  };

  const setLoading = () => {
    if (withdrawals.length > 0) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLoading();
  }, [withdrawals]);

  // console.log('transactions data>>', data);
  // console.log('tttt>>>', transactionData);

  return (
    <Page className={classes.root} title="Wallet Transactions">
      <Container maxWidth={false}>
        <Box mb={4}>
          <Toolbar />
        </Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {isLoading ? (
                <TableLoader />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Previous Balance</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Balance</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell> Date&Time</TableCell>
                      <TableCell>Withdrawal Actions</TableCell>
                      <TableCell>Retry Admin</TableCell>
                      <TableCell>Cancelled Admin</TableCell>
                      <TableCell>Summary</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {withdrawals.map(withdraw => (
                      <TransactionRow
                        key={withdraw.id}
                        transaction={withdraw}
                        handleRefetch={handleRefetch}
                        onClick={() =>
                          navigate(`/app/wallet/transactions/${withdraw.id}`)
                        }
                        style={{ cursor: 'pointer' }}
                        hover
                        isRemoveAdminAction
                      />
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
          {data.pagination && (
            <TablePagination
              count={data.pagination.totalCount}
              page={data.pagination.currentPage}
              rowsPerPage={data.pagination.rows}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
